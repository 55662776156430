import React from 'react';

interface CountBadgeProps {
  count: number;
  label: string;
}

const CountBadge: React.FC<CountBadgeProps> = ({ count, label }) => {
  return (
    <div className="flex items-center justify-between bg-gray-50 rounded-lg p-4 mb-4">
      <span className="text-sm font-medium text-gray-600">{label}</span>
      <span className="px-3 py-1 bg-indigo-100 text-indigo-700 rounded-full text-sm font-semibold">
        {count.toLocaleString()}
      </span>
    </div>
  );
};

export default CountBadge;