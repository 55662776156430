import React from 'react';
import { ArrowRight } from 'lucide-react';

interface ExtractedTextPreviewProps {
  text: string;
  onImport: () => void;
}

const ExtractedTextPreview: React.FC<ExtractedTextPreviewProps> = ({ text, onImport }) => {
  return (
    <div className="space-y-4">
      <div className="p-4 bg-white/10 border border-white/10 rounded-lg">
        <pre className="text-white/90 text-sm whitespace-pre-wrap font-mono max-h-96 overflow-y-auto">
          {text}
        </pre>
      </div>

      <button
        onClick={onImport}
        className="flex items-center gap-2 px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 transition-colors"
      >
        Import to Clinical Review
        <ArrowRight className="h-4 w-4" />
      </button>
    </div>
  );
};

export default ExtractedTextPreview;