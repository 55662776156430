import React, { useState, useCallback } from 'react';
import { useData } from '../../context/DataContext';
import { Search, Loader2, RefreshCw, Plus, Trash2 } from 'lucide-react';
import FileUploader from './FileUploader';
import TableActions from './TableActions';
import CountBadge from './CountBadge';
import { debounce } from '../../lib/utils';

interface CriteriaItem {
  guideline: string;
  criteria: string;
  timestamp?: string;
}

const CriteriaTab = () => {
  const { criteria, loadData, updateData, loading } = useData();
  const [searchTerm, setSearchTerm] = useState('');
  const [isInitialized, setIsInitialized] = useState(false);
  const [showAddForm, setShowAddForm] = useState(false);
  const [newCriteria, setNewCriteria] = useState<Partial<CriteriaItem>>({
    guideline: '',
    criteria: ''
  });

  const handleLoadData = async () => {
    if (!isInitialized) {
      await loadData('criteria');
      setIsInitialized(true);
    }
  };

  const debouncedSearch = useCallback(
    debounce((value: string) => setSearchTerm(value), 300),
    []
  );

  const filteredCriteria = criteria.filter(item => {
    const search = searchTerm.toLowerCase();
    return (
      (item.guideline?.toString().toLowerCase().includes(search) ||
      item.criteria?.toString().toLowerCase().includes(search))
    );
  });

  const handleAddCriteria = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!newCriteria.guideline || !newCriteria.criteria) return;

    try {
      const updatedCriteria = [
        ...criteria,
        {
          ...newCriteria,
          timestamp: new Date().toISOString()
        }
      ];
      await updateData('criteria', updatedCriteria);
      setNewCriteria({ guideline: '', criteria: '' });
      setShowAddForm(false);
    } catch (error) {
      console.error('Error adding criteria:', error);
    }
  };

  const handleDelete = async (index: number) => {
    try {
      const updatedCriteria = criteria.filter((_, i) => i !== index);
      await updateData('criteria', updatedCriteria);
    } catch (error) {
      console.error('Error deleting criteria:', error);
    }
  };

  if (loading['criteria']) {
    return (
      <div className="flex justify-center items-center h-64">
        <Loader2 className="h-8 w-8 animate-spin text-indigo-600" />
      </div>
    );
  }

  return (
    <div className="space-y-4">
      <CountBadge count={criteria.length} label="Total Criteria" />

      <div className="flex justify-between items-center">
        <div className="relative flex-1 max-w-md">
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 h-5 w-5" />
          <input
            type="text"
            placeholder="Search criteria..."
            onChange={(e) => debouncedSearch(e.target.value)}
            className="pl-10 pr-4 py-2 w-full border rounded-lg focus:ring-2 focus:ring-indigo-500"
          />
        </div>
        <div className="flex items-center space-x-4">
          {!isInitialized && (
            <button
              onClick={handleLoadData}
              className="flex items-center px-3 py-2 text-sm font-medium text-indigo-600 hover:text-indigo-700"
            >
              <RefreshCw className="h-4 w-4 mr-2" />
              Load Data
            </button>
          )}
          <button
            onClick={() => setShowAddForm(true)}
            className="flex items-center px-3 py-2 text-sm font-medium text-white bg-indigo-600 rounded-lg hover:bg-indigo-700"
          >
            <Plus className="h-4 w-4 mr-2" />
            Add Criteria
          </button>
          <FileUploader type="criteria" />
        </div>
      </div>

      {showAddForm && (
        <form onSubmit={handleAddCriteria} className="bg-gray-50 p-4 rounded-lg mb-4">
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Guideline Title
              </label>
              <input
                type="text"
                value={newCriteria.guideline}
                onChange={(e) => setNewCriteria(prev => ({ ...prev, guideline: e.target.value }))}
                className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-indigo-500"
                placeholder="Enter guideline title..."
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Criteria
              </label>
              <textarea
                value={newCriteria.criteria}
                onChange={(e) => setNewCriteria(prev => ({ ...prev, criteria: e.target.value }))}
                rows={4}
                className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-indigo-500"
                placeholder="Enter criteria..."
              />
            </div>
            <div className="flex justify-end space-x-3">
              <button
                type="button"
                onClick={() => setShowAddForm(false)}
                className="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 rounded-lg hover:bg-gray-200"
              >
                Cancel
              </button>
              <button
                type="submit"
                disabled={!newCriteria.guideline || !newCriteria.criteria}
                className="px-4 py-2 text-sm font-medium text-white bg-indigo-600 rounded-lg hover:bg-indigo-700 disabled:opacity-50"
              >
                Add
              </button>
            </div>
          </div>
        </form>
      )}

      <div className="bg-white rounded-lg shadow overflow-hidden">
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Guideline
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Criteria
                </th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider w-20">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {filteredCriteria.map((item, index) => (
                <tr key={index} className="hover:bg-gray-50">
                  <td className="px-6 py-4 whitespace-normal text-sm text-gray-900">
                    {item.guideline}
                  </td>
                  <td className="px-6 py-4 whitespace-normal text-sm text-gray-500">
                    {item.criteria}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-right text-sm">
                    <button
                      onClick={() => handleDelete(index)}
                      className="text-red-600 hover:text-red-900"
                    >
                      <Trash2 className="h-4 w-4" />
                    </button>
                  </td>
                </tr>
              ))}
              {filteredCriteria.length === 0 && (
                <tr>
                  <td colSpan={3} className="px-6 py-4 text-center text-sm text-gray-500">
                    No criteria found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default CriteriaTab;