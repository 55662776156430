import React, { useState, useCallback, useEffect } from 'react';
import { useData } from '../../context/DataContext';
import { Search, Loader2, Plus, Trash2 } from 'lucide-react';
import FileUploader from './FileUploader';
import CountBadge from './CountBadge';
import { debounce } from '../../lib/utils';

const LobTab = () => {
  const { cptCodes, loadData, updateData, loading } = useData();
  const [searchTerm, setSearchTerm] = useState('');
  const [newLob, setNewLob] = useState('');
  const [showAddForm, setShowAddForm] = useState(false);

  // Load data when component mounts
  useEffect(() => {
    const loadCptCodes = async () => {
      try {
        await loadData('cptCodes');
      } catch (error) {
        console.error('Error loading CPT codes:', error);
      }
    };
    loadCptCodes();
  }, []); // Empty dependency array to load once on mount

  const debouncedSearch = useCallback(
    debounce((value: string) => setSearchTerm(value), 300),
    []
  );

  // Get unique LOBs and their counts
  const lobStats = React.useMemo(() => {
    const stats = new Map();
    cptCodes.forEach(code => {
      if (code.lob) {
        stats.set(code.lob, (stats.get(code.lob) || 0) + 1);
      }
    });
    return Array.from(stats.entries())
      .map(([lob, count]) => ({ lob, count }))
      .sort((a, b) => a.lob.localeCompare(b.lob));
  }, [cptCodes]);

  const filteredLobs = lobStats.filter(item => 
    item.lob.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleAddLob = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!newLob.trim()) return;

    try {
      // Add a dummy CPT code with the new LOB to ensure it's tracked
      const updatedCptCodes = [
        ...cptCodes,
        {
          code: 'PLACEHOLDER',
          lob: newLob.trim(),
          guideline: 'LOB Placeholder'
        }
      ];
      await updateData('cptCodes', updatedCptCodes);
      setNewLob('');
      setShowAddForm(false);
    } catch (error) {
      console.error('Error adding LOB:', error);
    }
  };

  const handleDeleteLob = async (lobToDelete: string) => {
    try {
      // Remove all CPT codes with this LOB
      const updatedCptCodes = cptCodes.filter(code => code.lob !== lobToDelete);
      await updateData('cptCodes', updatedCptCodes);
    } catch (error) {
      console.error('Error deleting LOB:', error);
    }
  };

  if (loading['cptCodes']) {
    return (
      <div className="flex justify-center items-center h-64">
        <Loader2 className="h-8 w-8 animate-spin text-indigo-600" />
      </div>
    );
  }

  return (
    <div className="space-y-4">
      <CountBadge count={lobStats.length} label="Total Lines of Business" />

      <div className="flex justify-between items-center">
        <div className="relative flex-1 max-w-md">
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 h-5 w-5" />
          <input
            type="text"
            placeholder="Search LOBs..."
            onChange={(e) => debouncedSearch(e.target.value)}
            className="pl-10 pr-4 py-2 w-full border rounded-lg focus:ring-2 focus:ring-indigo-500"
          />
        </div>
        <div className="flex items-center space-x-4">
          <button
            onClick={() => setShowAddForm(true)}
            className="flex items-center px-3 py-2 text-sm font-medium text-white bg-indigo-600 rounded-lg hover:bg-indigo-700"
          >
            <Plus className="h-4 w-4 mr-2" />
            Add LOB
          </button>
        </div>
      </div>

      {showAddForm && (
        <form onSubmit={handleAddLob} className="bg-gray-50 p-4 rounded-lg mb-4">
          <div className="flex items-center space-x-4">
            <input
              type="text"
              value={newLob}
              onChange={(e) => setNewLob(e.target.value)}
              placeholder="Enter new LOB name"
              className="flex-1 px-3 py-2 border rounded-lg focus:ring-2 focus:ring-indigo-500"
            />
            <button
              type="submit"
              className="px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700"
            >
              Add
            </button>
            <button
              type="button"
              onClick={() => setShowAddForm(false)}
              className="px-4 py-2 bg-gray-200 text-gray-700 rounded-lg hover:bg-gray-300"
            >
              Cancel
            </button>
          </div>
        </form>
      )}

      <div className="bg-white rounded-lg shadow overflow-hidden">
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Line of Business
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Associated CPT Codes
                </th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {filteredLobs.map((item, index) => (
                <tr key={index} className="hover:bg-gray-50">
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                    {item.lob}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {item.count}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-right text-sm">
                    <button
                      onClick={() => handleDeleteLob(item.lob)}
                      className="text-red-600 hover:text-red-900"
                    >
                      <Trash2 className="h-4 w-4" />
                    </button>
                  </td>
                </tr>
              ))}
              {filteredLobs.length === 0 && (
                <tr>
                  <td colSpan={3} className="px-6 py-4 text-center text-sm text-gray-500">
                    No lines of business found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default LobTab;