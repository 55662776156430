import React, { useState } from 'react';
import { Download, Plus, Search } from 'lucide-react';
import Papa from 'papaparse';
import * as XLSX from 'xlsx';

interface TableActionsProps {
  data: any[];
  type: string;
  onDataUpdate: (newData: any[]) => void;
  onSearch?: (term: string) => void;
}

const TableActions: React.FC<TableActionsProps> = ({
  data,
  type,
  onDataUpdate,
  onSearch
}) => {
  const [showDropdown, setShowDropdown] = useState(false);

  const downloadCSV = () => {
    try {
      const csv = Papa.unparse(data);
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = `${type}_${new Date().toISOString().split('T')[0]}.csv`;
      link.click();
      setShowDropdown(false);
    } catch (error) {
      console.error('Error downloading CSV:', error);
    }
  };

  const downloadExcel = () => {
    try {
      const ws = XLSX.utils.json_to_sheet(data);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, type);
      XLSX.writeFile(wb, `${type}_${new Date().toISOString().split('T')[0]}.xlsx`);
      setShowDropdown(false);
    } catch (error) {
      console.error('Error downloading Excel:', error);
    }
  };

  const handleAdd = () => {
    const newEntry = type === 'cptCodes' 
      ? { code: '', lob: '', guideline: '' }
      : type === 'criteria'
      ? { guideline: '', criteria: '' }
      : { code: '', description: '', procedureMd: '0' };

    onDataUpdate([...data, newEntry]);
  };

  return (
    <div className="flex items-center space-x-4">
      {onSearch && (
        <div className="relative">
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 h-5 w-5" />
          <input
            type="text"
            placeholder="Search..."
            onChange={(e) => onSearch(e.target.value)}
            className="pl-10 pr-4 py-2 border rounded-lg focus:ring-2 focus:ring-black"
          />
        </div>
      )}

      <div className="flex items-center space-x-2">
        <button
          onClick={handleAdd}
          className="px-3 py-2 text-sm font-medium text-white bg-black rounded-lg hover:bg-gray-900 transition-colors"
          title="Add New Entry"
        >
          <Plus className="h-4 w-4" />
        </button>

        <div className="relative">
          <button
            onClick={() => setShowDropdown(!showDropdown)}
            className="px-3 py-2 text-sm font-medium text-gray-700 bg-gray-100 rounded-lg hover:bg-gray-200 transition-colors"
            title="Download"
          >
            <Download className="h-4 w-4" />
          </button>

          {showDropdown && (
            <div className="absolute right-0 mt-2 w-48 bg-white rounded-lg shadow-lg border border-gray-200 py-1 z-10">
              <button
                onClick={downloadCSV}
                className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
              >
                Download CSV
              </button>
              <button
                onClick={downloadExcel}
                className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
              >
                Download Excel
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TableActions;