import { LocalStorageService } from './LocalStorageService';
import Papa from 'papaparse';
import * as XLSX from 'xlsx';
import { debounce } from '../lib/utils';

const CHUNK_SIZE = 1000;

export class DataService {
  private static processQueue = Promise.resolve();

  static async parseFile(file: File): Promise<any[]> {
    return new Promise((resolve, reject) => {
      if (file.name.toLowerCase().endsWith('.csv')) {
        Papa.parse(file, {
          header: true,
          skipEmptyLines: 'greedy',
          transformHeader: (header) => {
            const h = header.toLowerCase().trim();
            
            // CPT Code mappings
            if (['cpt code', 'cptcode', 'code', 'cpt'].includes(h)) {
              return 'code';
            }
            
            // Guidelines mappings
            if (['guidelines', 'guideline', 'title'].includes(h)) {
              return 'guideline';
            }
            
            // Criteria mappings
            if (['criteria', 'criterion'].includes(h)) {
              return 'criteria';
            }
            
            // Description mappings (for procedures)
            if (['description', 'desc'].includes(h)) {
              return 'description';
            }
            
            // LOB mappings
            if (['line of business', 'lob', 'line_of_business'].includes(h)) {
              return 'lob';
            }
            
            // Procedure MD mappings
            if (['procedure md', 'proceduremd', 'procedure_md'].includes(h)) {
              return 'procedureMd';
            }

            return h;
          },
          complete: (results) => {
            if (results.errors.length > 0) {
              console.error('CSV parsing errors:', results.errors);
              reject(new Error(`CSV parsing errors: ${results.errors.map(e => e.message).join(', ')}`));
              return;
            }
            resolve(this.normalizeData(results.data));
          },
          error: (error) => reject(new Error(`CSV parsing error: ${error}`))
        });
      } else if (file.name.toLowerCase().match(/\.xlsx?$/)) {
        const reader = new FileReader();
        reader.onload = async (e) => {
          try {
            const data = e.target?.result;
            if (!data) {
              reject(new Error('Failed to read Excel file'));
              return;
            }
            const workbook = XLSX.read(data, { type: 'binary' });
            const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
            
            // Convert headers to match expected format
            const range = XLSX.utils.decode_range(firstSheet['!ref'] || 'A1:Z1');
            for (let C = range.s.c; C <= range.e.c; ++C) {
              const cell = firstSheet[XLSX.utils.encode_cell({ r: range.s.r, c: C })];
              if (cell && cell.v) {
                const header = cell.v.toString().toLowerCase().trim();
                if (['guidelines', 'guideline', 'title'].includes(header)) {
                  cell.v = 'guideline';
                } else if (['criteria', 'criterion'].includes(header)) {
                  cell.v = 'criteria';
                }
              }
            }

            const jsonData = XLSX.utils.sheet_to_json(firstSheet);
            resolve(this.normalizeData(jsonData));
          } catch (error) {
            reject(new Error(`Excel parsing error: ${error instanceof Error ? error.message : 'Unknown error'}`));
          }
        };
        reader.onerror = () => reject(new Error('File reading error'));
        reader.readAsBinaryString(file);
      } else {
        reject(new Error('Unsupported file format. Please use CSV or Excel files.'));
      }
    });
  }

  private static normalizeData(data: any[]): any[] {
    return data.map(record => {
      const normalized: any = {};
      
      Object.entries(record).forEach(([key, value]) => {
        if (!value || key.match(/^_\d+$/)) return;
        
        const normalizedKey = key.toLowerCase().trim();
        const normalizedValue = value.toString().trim();
        
        // Map fields based on key
        if (['guidelines', 'guideline', 'title'].includes(normalizedKey)) {
          normalized.guideline = normalizedValue;
        } else if (['criteria', 'criterion'].includes(normalizedKey)) {
          normalized.criteria = normalizedValue;
        } else if (['cpt code', 'cptcode', 'code', 'cpt'].includes(normalizedKey)) {
          normalized.code = normalizedValue;
        } else if (['description', 'desc'].includes(normalizedKey)) {
          normalized.description = normalizedValue;
        } else if (['line of business', 'lob'].includes(normalizedKey)) {
          normalized.lob = normalizedValue;
        } else if (['procedure md', 'proceduremd'].includes(normalizedKey)) {
          normalized.procedureMd = normalizedValue || '0';
        }
      });

      return normalized;
    }).filter(record => {
      // Keep records that have required fields based on type
      if (record.guideline && record.criteria) return true; // Criteria
      if (record.code && record.description) return true; // Procedures
      if (record.code && record.lob) return true; // CPT Codes
      return false;
    });
  }

  static validateData = debounce((data: any[], type: string): any[] => {
    return data.filter(record => {
      switch (type) {
        case 'criteria':
          return Boolean(record.guideline?.toString().trim() && record.criteria?.toString().trim());
        case 'procedures':
          return Boolean(record.code?.toString().trim() && record.description?.toString().trim());
        case 'cptCodes':
          return Boolean(record.code?.toString().trim() && record.lob?.toString().trim());
        default:
          return false;
      }
    }).map(record => {
      const cleanRecord = { ...record };
      Object.keys(cleanRecord).forEach(key => {
        if (typeof cleanRecord[key] === 'string') {
          cleanRecord[key] = cleanRecord[key].trim();
        }
      });
      return cleanRecord;
    });
  }, 100);

  static async queueOperation<T>(operation: () => Promise<T>): Promise<T> {
    this.processQueue = this.processQueue.then(async () => {
      try {
        return await operation();
      } catch (error) {
        console.error('Operation error:', error);
        throw error;
      }
    });
    return this.processQueue as Promise<T>;
  }
}