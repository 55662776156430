import React, { useState, useEffect } from 'react';
import { Bell } from 'lucide-react';
import { SubscriptionAlertManager } from '../../components/admin/SubscriptionAlertManager';

const SubscriptionAlerts = () => {
  return (
    <div className="bg-white rounded-lg shadow-sm p-6">
      <div className="flex items-center gap-2 mb-6">
        <Bell className="h-6 w-6 text-indigo-600" />
        <h1 className="text-2xl font-bold text-gray-900">Subscription Alerts</h1>
      </div>
      <SubscriptionAlertManager />
    </div>
  );
};

export default SubscriptionAlerts;