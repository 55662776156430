import React from 'react';
import { BarChart, Clock, FileText, Calendar, TrendingUp } from 'lucide-react';
import { useAuth } from '../../context/AuthContext';
import { useReviewStore } from '../../store/reviewStore';

const UserAnalytics = () => {
  const { user } = useAuth();
  const { totalReviews } = useReviewStore();

  // Sample analytics data (in a real app, this would come from a backend)
  const analyticsData = {
    totalReviews,
    averageTime: '8.5 minutes',
    completionRate: '95%',
    weeklyReviews: [12, 15, 10, 8, 14, 11, 13],
    topCptCodes: [
      { code: '99214', count: 45 },
      { code: '99213', count: 38 },
      { code: '99215', count: 22 }
    ]
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-indigo-900 via-blue-900 to-blue-800 py-8 px-4">
      <div className="max-w-7xl mx-auto">
        <div className="mb-8">
          <h1 className="text-2xl font-bold text-white mb-2">Analytics Dashboard</h1>
          <p className="text-white/80">Welcome back, {user?.name}</p>
        </div>

        {/* Key Metrics */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
          {[
            { 
              title: 'Total Reviews',
              value: analyticsData.totalReviews,
              icon: FileText,
              color: 'from-blue-500 to-blue-600'
            },
            {
              title: 'Average Review Time',
              value: analyticsData.averageTime,
              icon: Clock,
              color: 'from-green-500 to-green-600'
            },
            {
              title: 'Completion Rate',
              value: analyticsData.completionRate,
              icon: TrendingUp,
              color: 'from-purple-500 to-purple-600'
            },
            {
              title: 'Reviews This Week',
              value: analyticsData.weeklyReviews[analyticsData.weeklyReviews.length - 1],
              icon: Calendar,
              color: 'from-orange-500 to-orange-600'
            }
          ].map((metric, index) => (
            <div key={index} className="relative group">
              <div className="absolute inset-0 bg-gradient-to-r from-blue-500/20 to-indigo-500/20 rounded-xl blur-lg"></div>
              <div className="relative backdrop-blur-md bg-white/10 border border-white/20 p-6 rounded-xl">
                <div className="flex items-center justify-between">
                  <metric.icon className="h-8 w-8 text-white/80" />
                  <span className="text-2xl font-bold text-white">{metric.value}</span>
                </div>
                <p className="mt-2 text-sm text-white/80">{metric.title}</p>
              </div>
            </div>
          ))}
        </div>

        {/* Weekly Review Trend */}
        <div className="relative group mb-8">
          <div className="absolute inset-0 bg-gradient-to-r from-blue-500/20 to-indigo-500/20 rounded-xl blur-lg"></div>
          <div className="relative backdrop-blur-md bg-white/10 border border-white/20 p-6 rounded-xl">
            <h2 className="text-lg font-semibold text-white mb-4">Weekly Review Trend</h2>
            <div className="h-48 flex items-end justify-between">
              {analyticsData.weeklyReviews.map((count, index) => (
                <div key={index} className="flex flex-col items-center">
                  <div 
                    className="w-12 bg-gradient-to-t from-blue-500/50 to-indigo-500/50 rounded-t"
                    style={{ height: `${(count / Math.max(...analyticsData.weeklyReviews)) * 100}%` }}
                  ></div>
                  <span className="mt-2 text-sm text-white/80">Day {index + 1}</span>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Top CPT Codes */}
        <div className="relative group">
          <div className="absolute inset-0 bg-gradient-to-r from-blue-500/20 to-indigo-500/20 rounded-xl blur-lg"></div>
          <div className="relative backdrop-blur-md bg-white/10 border border-white/20 p-6 rounded-xl">
            <h2 className="text-lg font-semibold text-white mb-4">Most Used CPT Codes</h2>
            <div className="space-y-4">
              {analyticsData.topCptCodes.map((cpt, index) => (
                <div key={index} className="flex items-center justify-between">
                  <span className="text-white/80">CPT {cpt.code}</span>
                  <div className="flex-1 mx-4">
                    <div className="h-2 bg-white/10 rounded-full overflow-hidden">
                      <div 
                        className="h-full bg-gradient-to-r from-blue-500 to-indigo-500"
                        style={{ width: `${(cpt.count / analyticsData.topCptCodes[0].count) * 100}%` }}
                      ></div>
                    </div>
                  </div>
                  <span className="text-white/80">{cpt.count} reviews</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserAnalytics;