import { openAISettings } from './config/settings';
import { TOKEN_LIMITS } from './tokenizer';

export const openAIConfig = {
  apiKey: openAISettings.apiKey,
  model: 'gpt-3.5-turbo-16k',
  temperature: 0.3,
  maxTokens: TOKEN_LIMITS.MAX_COMPLETION_TOKENS,
  chunkSize: TOKEN_LIMITS.CHUNK_SIZE,
  retryAttempts: 3,
  retryDelay: 1000,
};