import React from 'react';
import { Eraser } from 'lucide-react';
import { useFormStore } from '../store/formStore';
import { useReviewStore } from '../store/reviewStore';

const ReviewCounter = () => {
  const { clearForm, stopTimer } = useFormStore();
  const { totalReviews, resetReviews } = useReviewStore();

  const handleReset = () => {
    stopTimer();
    clearForm();
    resetReviews();
  };

  return (
    <div className="flex items-center space-x-4 bg-white rounded-lg shadow px-4 py-2">
      <div className="flex items-center space-x-2">
        <span className="text-sm font-medium text-gray-700">Total Reviews:</span>
        <span className="text-lg font-bold text-indigo-600">{totalReviews}</span>
      </div>
      <button
        onClick={handleReset}
        className="px-3 py-1 text-sm text-gray-600 hover:text-gray-800 hover:bg-gray-100 rounded-md flex items-center space-x-1"
        title="Reset Counter"
      >
        <Eraser className="h-4 w-4 mr-1" />
        <span>Reset</span>
      </button>
    </div>
  );
};

export default ReviewCounter;