import { useState, useCallback } from 'react';
import { Review } from '../types';
import { OpenAIService } from '../services/openai/service';
import { truncateText } from '../services/openai/tokenizer';

interface ReviewGenerationState {
  isGenerating: boolean;
  error: string | null;
}

interface CPTDescription {
  code: string;
  description: string;
}

export const useReviewGeneration = () => {
  const [state, setState] = useState<ReviewGenerationState>({
    isGenerating: false,
    error: null
  });

  const generateReview = useCallback(async (
    clinicalNotes: string,
    cptDescriptions: CPTDescription[],
    selectedGuidelines: string[],
    aiFocus?: string
  ): Promise<Review | null> => {
    if (!clinicalNotes?.trim()) {
      throw new Error('Clinical notes are required');
    }

    setState({ isGenerating: true, error: null });

    try {
      // Truncate clinical notes if needed
      const truncatedNotes = truncateText(clinicalNotes);

      // Format CPT descriptions
      const formattedCptDescriptions = cptDescriptions
        .map(desc => `${desc.code}: ${desc.description}`)
        .join('\n');

      // Process in parallel with error handling
      const [dateResponse, medicalHistory, recommendation] = await Promise.allSettled([
        OpenAIService.analyzeClinicalNotes(truncatedNotes, 'date_of_service', []),
        OpenAIService.analyzeClinicalNotes(truncatedNotes, 'medical_history', []),
        OpenAIService.analyzeClinicalNotes(
          `${truncatedNotes}\n\nCPT Codes:\n${formattedCptDescriptions}`, 
          'recommendation', 
          selectedGuidelines
        )
      ]);

      // Handle responses
      const review: Review = {
        reasonForRequest: formattedCptDescriptions || 'No CPT code description available',
        dateOfService: dateResponse.status === 'fulfilled' ? dateResponse.value.summary : new Date().toLocaleDateString(),
        medicalHistory: medicalHistory.status === 'fulfilled' ? medicalHistory.value.summary : 'No medical history found',
        criteriaApplied: selectedGuidelines,
        recommendation: recommendation.status === 'fulfilled' ? recommendation.value.summary : 'Please select guidelines for review',
        signature: 'C. Starks, LVN',
        additionalNote: 'N/A, current attached clinical notes reviewed.'
      };

      setState({ isGenerating: false, error: null });
      return review;

    } catch (error) {
      console.error('Error generating review:', error);
      setState({
        isGenerating: false,
        error: error instanceof Error ? error.message : 'Failed to generate review'
      });
      throw error;
    }
  }, []);

  return {
    ...state,
    generateReview
  };
};