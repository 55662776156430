import React, { useState, useCallback } from 'react';
import { useData } from '../../context/DataContext';
import { Trash2, Loader2 } from 'lucide-react';
import FileUploader from './FileUploader';
import TableActions from './TableActions';
import CountBadge from './CountBadge';
import { debounce } from 'lodash';

const CptCodesTab = () => {
  const { cptCodes, updateData, loading } = useData();
  const [searchTerm, setSearchTerm] = useState('');
  const [error, setError] = useState<string | null>(null);

  const debouncedSearch = useCallback(
    debounce((value: string) => setSearchTerm(value), 300),
    []
  );

  const filteredCodes = React.useMemo(() => {
    return cptCodes.filter(code => {
      const search = searchTerm.toLowerCase();
      return (
        code.code?.toString().toLowerCase().includes(search) ||
        code.lob?.toLowerCase().includes(search) ||
        code.guideline?.toLowerCase().includes(search)
      );
    });
  }, [cptCodes, searchTerm]);

  if (loading['cptCodes']) {
    return (
      <div className="flex justify-center items-center h-64">
        <Loader2 className="h-8 w-8 animate-spin text-black" />
      </div>
    );
  }

  return (
    <div className="space-y-4">
      <CountBadge count={cptCodes.length} label="Total CPT Codes" />
      
      <div className="flex justify-between items-center">
        <TableActions
          data={cptCodes}
          type="cptCodes"
          onDataUpdate={(newData) => updateData('cptCodes', newData)}
          onSearch={debouncedSearch}
        />
        <div className="flex items-center space-x-4">
          <FileUploader type="cptCodes" />
        </div>
      </div>

      <div className="bg-white rounded-lg shadow overflow-hidden">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                CPT Code
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                LOB
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Guideline
              </th>
              <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {filteredCodes.map((code, index) => (
              <tr key={index} className="hover:bg-gray-50">
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {code.code}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {code.lob}
                </td>
                <td className="px-6 py-4 text-sm text-gray-500">
                  {code.guideline}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-right text-sm">
                  <button
                    onClick={() => {
                      const newCodes = [...cptCodes];
                      newCodes.splice(index, 1);
                      updateData('cptCodes', newCodes);
                    }}
                    className="text-red-600 hover:text-red-900"
                  >
                    <Trash2 className="h-4 w-4" />
                  </button>
                </td>
              </tr>
            ))}
            {filteredCodes.length === 0 && (
              <tr>
                <td colSpan={4} className="px-6 py-4 text-center text-sm text-gray-500">
                  No CPT codes found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {error && (
        <div className="mt-4 p-4 bg-red-50 rounded-md text-sm text-red-600">
          {error}
        </div>
      )}
    </div>
  );
};

export default CptCodesTab;