import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { Upload, Loader2, FileText } from 'lucide-react';
import { FileValidator } from '../../services/ocr/fileValidator';

interface FileDropzoneProps {
  onFilesAccepted: (files: File[]) => void;
  processing: boolean;
}

const FileDropzone: React.FC<FileDropzoneProps> = ({ onFilesAccepted, processing }) => {
  const onDrop = useCallback((acceptedFiles: File[]) => {
    const validation = FileValidator.validateFiles(acceptedFiles);
    if (!validation.valid) {
      console.error('File validation failed:', validation.error);
      return;
    }
    onFilesAccepted(acceptedFiles);
  }, [onFilesAccepted]);

  const { getRootProps, getInputProps, isDragActive, acceptedFiles } = useDropzone({
    onDrop,
    accept: {
      'image/*': ['.png', '.jpg', '.jpeg', '.gif', '.bmp', '.webp'],
      'application/pdf': ['.pdf']
    },
    multiple: true,
    disabled: processing,
    maxFiles: 40,
    maxSize: 10485760 // 10MB
  });

  return (
    <div className="space-y-4">
      <div
        {...getRootProps()}
        className={`
          relative group flex flex-col items-center justify-center w-full p-8
          border-2 border-dashed rounded-xl transition-all duration-300
          ${processing 
            ? 'border-gray-500 bg-gray-800/20 cursor-not-allowed' 
            : isDragActive
              ? 'border-indigo-500/50 bg-white/5'
              : 'border-white/20 hover:border-indigo-500/50 hover:bg-white/5'
          }
        `}
      >
        <input {...getInputProps()} />
        
        {processing ? (
          <div className="flex flex-col items-center gap-3">
            <Loader2 className="h-10 w-10 text-white animate-spin" />
            <p className="text-white text-sm">Processing documents...</p>
          </div>
        ) : (
          <div className="flex flex-col items-center gap-3">
            <div className="p-3 bg-white/10 rounded-full">
              <Upload className="h-6 w-6 text-white" />
            </div>
            <div className="text-center">
              <p className="text-white text-sm font-medium">
                {isDragActive 
                  ? 'Drop the files here...' 
                  : 'Drag & drop files here, or click to select'}
              </p>
              <p className="text-white/60 text-xs mt-1">
                Supports PNG, JPG, JPEG, PDF (max 10MB per file, up to 40 pages total)
              </p>
            </div>
          </div>
        )}
      </div>

      {acceptedFiles.length > 0 && !processing && (
        <div className="bg-white/10 rounded-lg p-4">
          <p className="text-white/80 text-sm mb-2">Selected files:</p>
          <div className="space-y-2">
            {acceptedFiles.map((file, index) => (
              <div 
                key={index}
                className="flex items-center gap-2 text-white/60 text-sm"
              >
                <FileText className="h-4 w-4" />
                <span>{file.name}</span>
                <span className="text-xs">
                  ({(file.size / (1024 * 1024)).toFixed(2)} MB)
                </span>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default FileDropzone;