import React, { useState } from 'react';
import { AlertCircle } from 'lucide-react';
import { DataDiagnostics } from '../../utils/diagnostics';

interface DiagnosticButtonProps {
  onFileSelect: (file: File) => void;
}

const DiagnosticButton: React.FC<DiagnosticButtonProps> = ({ onFileSelect }) => {
  const [report, setReport] = useState<any>(null);
  const [showReport, setShowReport] = useState(false);

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    try {
      const diagnosticReport = await DataDiagnostics.analyzeFile(file);
      setReport(diagnosticReport);
      setShowReport(true);
      onFileSelect(file);
    } catch (error) {
      console.error('Diagnostic error:', error);
    }
  };

  return (
    <>
      <div className="relative">
        <input
          type="file"
          onChange={handleFileChange}
          accept=".csv,.xlsx,.xls"
          className="hidden"
          id="diagnostic-file-input"
        />
        <label
          htmlFor="diagnostic-file-input"
          className="cursor-pointer inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700"
        >
          Run Diagnostics
        </label>
      </div>

      {showReport && report && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 max-w-4xl w-full mx-4 max-h-[90vh] overflow-y-auto">
            <h2 className="text-xl font-semibold mb-4">File Analysis Report</h2>
            
            {report.errors.length > 0 && (
              <div className="mb-4 p-4 bg-red-50 rounded-md">
                <div className="flex items-center">
                  <AlertCircle className="h-5 w-5 text-red-400 mr-2" />
                  <h3 className="text-sm font-medium text-red-800">Errors Found</h3>
                </div>
                <ul className="mt-2 text-sm text-red-700">
                  {report.errors.map((error: string, index: number) => (
                    <li key={index}>{error}</li>
                  ))}
                </ul>
              </div>
            )}

            <div className="space-y-4">
              <div>
                <h3 className="font-medium mb-2">Header Analysis</h3>
                <div className="bg-gray-50 p-4 rounded-md">
                  <p><strong>Detected Headers:</strong> {report.headerAnalysis.detectedHeaders.join(', ')}</p>
                  <p><strong>Normalized Headers:</strong> {report.headerAnalysis.normalizedHeaders.join(', ')}</p>
                </div>
              </div>

              <div>
                <h3 className="font-medium mb-2">Data Analysis</h3>
                <div className="bg-gray-50 p-4 rounded-md">
                  <p><strong>Total Rows:</strong> {report.dataAnalysis.totalRows}</p>
                  <p><strong>Missing Required Fields:</strong> {report.dataAnalysis.missingRequiredFields.join(', ') || 'None'}</p>
                </div>
              </div>

              <div>
                <h3 className="font-medium mb-2">Sample Data</h3>
                <div className="overflow-x-auto">
                  <pre className="bg-gray-50 p-4 rounded-md text-sm">
                    {JSON.stringify(report.dataAnalysis.sampleData, null, 2)}
                  </pre>
                </div>
              </div>
            </div>

            <div className="mt-6 flex justify-end">
              <button
                onClick={() => setShowReport(false)}
                className="px-4 py-2 bg-gray-200 text-gray-700 rounded-md hover:bg-gray-300"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DiagnosticButton;