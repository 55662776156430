import { create } from 'zustand';

interface ReviewState {
  totalReviews: number;
  incrementReviews: () => void;
  resetReviews: () => void;
}

export const useReviewStore = create<ReviewState>((set) => ({
  totalReviews: 0,
  incrementReviews: () => set((state) => ({ totalReviews: state.totalReviews + 1 })),
  resetReviews: () => set({ totalReviews: 0 })
}));