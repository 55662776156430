export class OCRError extends Error {
  constructor(
    message: string,
    public code: string = 'ocr_error',
    public details?: any
  ) {
    super(message);
    this.name = 'OCRError';
  }

  static fileValidation(message: string): OCRError {
    return new OCRError(message, 'file_validation');
  }

  static processingError(message: string, details?: any): OCRError {
    return new OCRError(message, 'processing_error', details);
  }

  static initializationError(message: string): OCRError {
    return new OCRError(message, 'initialization_error');
  }

  static quotaExceeded(): OCRError {
    return new OCRError(
      'OCR processing quota exceeded. Please try again later.',
      'quota_exceeded'
    );
  }

  static unsupportedFormat(format: string): OCRError {
    return new OCRError(
      `Unsupported file format: ${format}. Please use PNG, JPG, JPEG, or PDF files.`,
      'unsupported_format'
    );
  }

  static fileTooLarge(size: number, limit: number): OCRError {
    return new OCRError(
      `File size (${Math.round(size / 1024 / 1024)}MB) exceeds limit of ${Math.round(limit / 1024 / 1024)}MB`,
      'file_too_large'
    );
  }

  static noTextFound(): OCRError {
    return new OCRError(
      'No text could be extracted from the file. Please try a different file or ensure the image is clear.',
      'no_text_found'
    );
  }

  static tooManyPages(pages: number, limit: number): OCRError {
    return new OCRError(
      `Document has ${pages} pages. Maximum allowed is ${limit} pages.`,
      'too_many_pages'
    );
  }

  static networkError(): OCRError {
    return new OCRError(
      'Network error occurred while processing the file. Please check your connection and try again.',
      'network_error'
    );
  }
}