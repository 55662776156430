import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { aiResponseCache } from '../services/AIResponseCache';

interface FormState {
  clinicalNotes: string;
  ocrText: string;
  selectedCptCodes: string[];
  selectedGuidelines: string[];
  selectedLOB: string;
  timerActive: boolean;
  timerStart: number | null;
  hasStartedFirstReview: boolean;
  aiFocus: string;
}

interface FormActions {
  setFormData: (data: Partial<FormState>) => void;
  clearForm: () => void;
  startTimer: () => void;
  stopTimer: () => void;
}

const initialState: FormState = {
  clinicalNotes: '',
  ocrText: '',
  selectedCptCodes: [],
  selectedGuidelines: [],
  selectedLOB: '',
  timerActive: false,
  timerStart: null,
  hasStartedFirstReview: false,
  aiFocus: ''
};

export const useFormStore = create<FormState & FormActions>()(
  persist(
    (set) => ({
      ...initialState,
      setFormData: (data) => set((state) => ({ ...state, ...data })),
      clearForm: () => {
        // Clear AI response cache
        aiResponseCache.clearCache();
        // Reset form state while preserving timer state
        set((state) => ({
          ...initialState,
          timerActive: state.timerActive,
          timerStart: state.timerStart
        }));
      },
      startTimer: () => {
        // Clear form and cache before starting timer
        aiResponseCache.clearCache();
        set({
          ...initialState,
          timerActive: true,
          timerStart: Date.now()
        });
      },
      stopTimer: () => set({ timerActive: false, timerStart: null })
    }),
    {
      name: 'form-storage',
      partialize: (state) => ({
        clinicalNotes: state.clinicalNotes,
        selectedCptCodes: state.selectedCptCodes,
        selectedGuidelines: state.selectedGuidelines,
        selectedLOB: state.selectedLOB,
        aiFocus: state.aiFocus
      })
    }
  )
);