export class FileValidator {
  private static readonly ALLOWED_TYPES = new Set([
    'image/png',
    'image/jpeg',
    'image/jpg',
    'image/gif',
    'image/bmp',
    'image/webp',
    'application/pdf'
  ]);

  private static readonly MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB per file
  private static readonly MAX_TOTAL_SIZE = 40 * 1024 * 1024; // 40MB total
  private static readonly MAX_FILES = 40;

  static validateFile(file: File): { valid: boolean; error?: string } {
    if (!this.ALLOWED_TYPES.has(file.type)) {
      return {
        valid: false,
        error: 'Invalid file type. Please upload PNG, JPG, JPEG, GIF, BMP, WEBP, or PDF files.'
      };
    }

    if (file.size > this.MAX_FILE_SIZE) {
      return {
        valid: false,
        error: 'File size exceeds 10MB limit.'
      };
    }

    return { valid: true };
  }

  static validateFiles(files: File[]): { valid: boolean; error?: string } {
    if (files.length === 0) {
      return {
        valid: false,
        error: 'No files selected.'
      };
    }

    if (files.length > this.MAX_FILES) {
      return {
        valid: false,
        error: `Maximum ${this.MAX_FILES} files allowed.`
      };
    }

    let totalSize = 0;
    for (const file of files) {
      const result = this.validateFile(file);
      if (!result.valid) {
        return result;
      }
      totalSize += file.size;
    }

    if (totalSize > this.MAX_TOTAL_SIZE) {
      return {
        valid: false,
        error: 'Total file size exceeds 40MB limit.'
      };
    }

    return { valid: true };
  }
}