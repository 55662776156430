import localforage from 'localforage';
import type { AIResponse } from '../types/medical';

const CACHE_EXPIRATION = 24 * 60 * 60 * 1000; // 24 hours

class AIResponseCache {
  private store: LocalForage;

  constructor() {
    this.store = localforage.createInstance({
      name: 'aiResponseCache',
    });
  }

  private generateKey(text: string, type: string): string {
    return `${type}_${text.slice(0, 50)}`;
  }

  async getResponse(text: string, type: string): Promise<string | null> {
    const key = this.generateKey(text, type);
    const cached: AIResponse | null = await this.store.getItem(key);

    if (!cached) return null;

    // Check if cache has expired
    if (Date.now() - cached.timestamp > CACHE_EXPIRATION) {
      await this.store.removeItem(key);
      return null;
    }

    return cached.summary;
  }

  async setResponse(text: string, type: string, summary: string): Promise<void> {
    const key = this.generateKey(text, type);
    const response: AIResponse = {
      summary,
      type,
      timestamp: Date.now(),
    };
    await this.store.setItem(key, response);
  }

  async clearCache(): Promise<void> {
    try {
      await this.store.clear();
      console.log('AI response cache cleared');
    } catch (error) {
      console.error('Error clearing AI response cache:', error);
    }
  }
}

export const aiResponseCache = new AIResponseCache();