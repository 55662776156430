export class AuthError extends Error {
  constructor(
    message: string,
    public code: string = 'auth_error',
    public originalError?: any
  ) {
    super(message);
    this.name = 'AuthError';
  }

  static invalidCredentials(): AuthError {
    return new AuthError(
      'Invalid username or password. Please check your credentials and try again.',
      'invalid_credentials'
    );
  }

  static missingCredentials(): AuthError {
    return new AuthError(
      'Username and password are required.',
      'missing_credentials'
    );
  }

  static sessionExists(): AuthError {
    return new AuthError(
      'Another session is already active. Please log out first.',
      'session_exists'
    );
  }
}