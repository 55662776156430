import { LocalStorageService } from '../../services/LocalStorageService';
import { aiResponseCache } from '../../services/AIResponseCache';

let initialized = false;
let initializationPromise: Promise<void> | null = null;

export const initializeSystem = async () => {
  if (initialized) return;
  if (initializationPromise) return initializationPromise;

  initializationPromise = (async () => {
    try {
      // Initialize storage
      await LocalStorageService.getData('auth_user');
      
      // Clear any stale cache
      await aiResponseCache.clearCache();

      initialized = true;
      console.log('System initialized successfully');
    } catch (error) {
      console.error('System initialization error:', error);
      throw error;
    } finally {
      initializationPromise = null;
    }
  })();

  return initializationPromise;
};

export const isSystemInitialized = () => initialized;