import { ADMIN_CREDENTIALS } from './adminCredentials';
import { AuthUser } from '../../types';
import { AuthError } from './errors';

export class AuthService {
  static validateCredentials(username: string, password: string): AuthUser {
    try {
      // Trim whitespace from credentials
      const trimmedUsername = username.trim();
      const trimmedPassword = password.trim();

      // Validate required fields
      if (!trimmedUsername || !trimmedPassword) {
        throw AuthError.missingCredentials();
      }

      // Case-sensitive exact match for admin credentials
      if (trimmedUsername === ADMIN_CREDENTIALS.username && 
          trimmedPassword === ADMIN_CREDENTIALS.password) {
        return {
          id: 'admin-1',
          email: ADMIN_CREDENTIALS.email,
          name: ADMIN_CREDENTIALS.name,
          active: true,
          isAdmin: true,
          credentials: ADMIN_CREDENTIALS.credentials
        };
      }

      // If credentials don't match, throw invalid credentials error
      throw AuthError.invalidCredentials();
    } catch (error) {
      // Re-throw AuthErrors as-is
      if (error instanceof AuthError) {
        throw error;
      }
      // Wrap other errors
      throw new AuthError(
        error instanceof Error ? error.message : 'Authentication failed',
        'auth_error',
        error
      );
    }
  }
}