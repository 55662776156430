import React, { useState, useEffect, useRef } from 'react';
import { Mic, AlertCircle } from 'lucide-react';

interface DictationButtonProps {
  onTextReceived: (text: string) => void;
}

const DictationButton: React.FC<DictationButtonProps> = ({ onTextReceived }) => {
  const [isRecording, setIsRecording] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const recognitionRef = useRef<any>(null);

  useEffect(() => {
    const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
    
    if (!SpeechRecognition) {
      setError('Speech recognition is not supported in your browser');
      return;
    }

    const recognition = new SpeechRecognition();
    recognition.continuous = true;
    recognition.interimResults = false;
    recognition.lang = 'en-US';
    
    recognition.onstart = () => {
      setIsRecording(true);
      setError(null);
    };

    recognition.onend = () => {
      // Only stop recording if manually triggered
      if (!isRecording) {
        setIsRecording(false);
      } else {
        // Restart recognition to keep it continuous
        try {
          recognition.start();
        } catch (err) {
          console.error('Error restarting recognition:', err);
          setIsRecording(false);
        }
      }
    };

    recognition.onresult = (event: any) => {
      const last = event.results.length - 1;
      const transcript = event.results[last][0].transcript;
      onTextReceived(transcript);
    };

    recognition.onerror = (event: any) => {
      if (event.error === 'no-speech') {
        setError('No speech detected. Please try again.');
      } else {
        setError(`Error: ${event.error}`);
      }
      setIsRecording(false);
    };

    recognitionRef.current = recognition;

    return () => {
      if (recognitionRef.current) {
        try {
          recognitionRef.current.stop();
        } catch (err) {
          console.error('Error stopping recognition:', err);
        }
      }
    };
  }, [onTextReceived]);

  const toggleRecording = () => {
    if (!recognitionRef.current) {
      setError('Speech recognition is not available');
      return;
    }

    if (isRecording) {
      try {
        recognitionRef.current.stop();
        setIsRecording(false);
      } catch (err) {
        console.error('Error stopping recognition:', err);
      }
    } else {
      setError(null);
      try {
        recognitionRef.current.start();
      } catch (err) {
        console.error('Error starting recognition:', err);
        setError('Failed to start recording. Please try again.');
      }
    }
  };

  return (
    <div className="flex flex-col items-center">
      <button
        onClick={toggleRecording}
        className={`p-3 rounded-full ${
          isRecording ? 'bg-red-500' : 'bg-blue-500'
        } text-white hover:opacity-90 transition-opacity`}
        aria-label={isRecording ? 'Stop recording' : 'Start recording'}
      >
        <Mic className={`h-6 w-6 ${isRecording ? 'animate-pulse' : ''}`} />
      </button>
      {error && (
        <div className="mt-2 flex items-center text-red-600 text-sm">
          <AlertCircle className="h-4 w-4 mr-1" />
          <span>{error}</span>
        </div>
      )}
    </div>
  );
};

export default DictationButton;