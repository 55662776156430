import { useState, useEffect, useRef } from 'react';
import { useAudioStore } from '../../store/audioStore';

export const useSpeechSynthesis = (text: string, autoPlay: boolean = false) => {
  const { isMuted, speechRate, selectedVoice } = useAudioStore();
  const [isPlaying, setIsPlaying] = useState(false);
  const [availableVoices, setAvailableVoices] = useState<SpeechSynthesisVoice[]>([]);
  const utteranceRef = useRef<SpeechSynthesisUtterance | null>(null);

  useEffect(() => {
    const loadVoices = () => {
      const voices = window.speechSynthesis.getVoices();
      setAvailableVoices(voices);
    };

    loadVoices();
    window.speechSynthesis.onvoiceschanged = loadVoices;

    return () => {
      window.speechSynthesis.onvoiceschanged = null;
      stopSpeaking();
    };
  }, []);

  const stopSpeaking = () => {
    if (utteranceRef.current) {
      window.speechSynthesis.cancel();
      setIsPlaying(false);
      utteranceRef.current = null;
    }
  };

  const startSpeaking = () => {
    if (isMuted || !text || isPlaying) return;

    stopSpeaking();

    const utterance = new SpeechSynthesisUtterance(text);
    utterance.rate = speechRate;

    if (selectedVoice) {
      const voice = availableVoices.find(v => v.name === selectedVoice);
      if (voice) {
        utterance.voice = voice;
      }
    }

    utteranceRef.current = utterance;

    utterance.onstart = () => setIsPlaying(true);
    utterance.onend = () => {
      setIsPlaying(false);
      utteranceRef.current = null;
    };
    utterance.onerror = () => {
      setIsPlaying(false);
      utteranceRef.current = null;
    };

    window.speechSynthesis.speak(utterance);
  };

  useEffect(() => {
    if (autoPlay && text && !isMuted) {
      startSpeaking();
    }
  }, [autoPlay, text, isMuted]);

  useEffect(() => {
    return () => {
      stopSpeaking();
    };
  }, []);

  useEffect(() => {
    if (utteranceRef.current && isPlaying) {
      const currentText = utteranceRef.current.text;
      stopSpeaking();
      const newUtterance = new SpeechSynthesisUtterance(currentText);
      newUtterance.rate = speechRate;
      if (selectedVoice) {
        const voice = availableVoices.find(v => v.name === selectedVoice);
        if (voice) {
          newUtterance.voice = voice;
        }
      }
      utteranceRef.current = newUtterance;
      window.speechSynthesis.speak(newUtterance);
    }
  }, [speechRate, selectedVoice]);

  return {
    isPlaying,
    isMuted,
    startSpeaking,
    stopSpeaking,
    availableVoices
  };
};