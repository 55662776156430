import React, { useState, useEffect } from 'react';
import { SubscriptionAlertSettings } from './billing/SubscriptionAlertSettings';
import { SubscriptionAlertList } from './billing/SubscriptionAlertList';
import { EmailTemplateEditor } from './billing/EmailTemplateEditor';
import { subscriberService } from '../../services/subscriber/SubscriberService';
import { emailService } from '../../services/email/EmailService';

export const SubscriptionAlertManager = () => {
  const [alerts, setAlerts] = useState<Array<{
    id: string;
    type: 'payment_failed' | 'subscription_ending' | 'subscription_canceled';
    subscriber: string;
    email: string;
    date: string;
    status: 'pending' | 'sent' | 'failed';
  }>>([]);

  const [settings, setSettings] = useState({
    paymentReminderDays: 3,
    subscriptionEndingDays: 7,
    gracePeriodDays: 3
  });

  const [selectedTemplate, setSelectedTemplate] = useState<string | null>(null);
  const [templates, setTemplates] = useState(emailService.getTemplates());

  useEffect(() => {
    loadAlerts();
  }, []);

  const loadAlerts = async () => {
    const atRiskSubscribers = subscriberService.getAtRiskSubscribers();
    const canceledSubscribers = subscriberService.getCanceledSubscribers();

    const newAlerts = [
      ...atRiskSubscribers.map(sub => ({
        id: crypto.randomUUID(),
        type: 'subscription_ending' as const,
        subscriber: sub.name,
        email: sub.email,
        date: sub.subscriptionEnd,
        status: 'pending' as const
      })),
      ...canceledSubscribers.map(sub => ({
        id: crypto.randomUUID(),
        type: 'subscription_canceled' as const,
        subscriber: sub.name,
        email: sub.email,
        date: sub.subscriptionEnd,
        status: 'pending' as const
      }))
    ];

    setAlerts(newAlerts);
  };

  const handleSendReminder = async (alert: typeof alerts[0]) => {
    try {
      await emailService.sendAlert(alert.type, {
        email: alert.email,
        name: alert.subscriber,
        date: alert.date
      });

      setAlerts(current =>
        current.map(a =>
          a.id === alert.id ? { ...a, status: 'sent' as const } : a
        )
      );
    } catch (error) {
      console.error('Error sending reminder:', error);
      setAlerts(current =>
        current.map(a =>
          a.id === alert.id ? { ...a, status: 'failed' as const } : a
        )
      );
    }
  };

  const handleUpdateTemplate = async (type: string, template: { subject: string; body: string }) => {
    try {
      await emailService.updateTemplate(type, template);
      setTemplates(emailService.getTemplates());
    } catch (error) {
      console.error('Error updating template:', error);
    }
  };

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between mb-4">
        <h2 className="text-lg font-semibold">Subscription Alerts</h2>
        <div className="flex items-center space-x-4">
          <select
            value={selectedTemplate || ''}
            onChange={(e) => setSelectedTemplate(e.target.value || null)}
            className="px-3 py-2 border rounded-lg"
          >
            <option value="">Select template to edit...</option>
            <option value="payment_failed">Payment Failed</option>
            <option value="subscription_ending">Subscription Ending</option>
            <option value="subscription_canceled">Subscription Canceled</option>
          </select>
        </div>
      </div>

      {selectedTemplate ? (
        <div className="bg-white rounded-lg shadow-sm p-6">
          <h3 className="text-lg font-medium mb-4">Edit Email Template</h3>
          <EmailTemplateEditor
            type={selectedTemplate as any}
            template={templates[selectedTemplate]}
            onSave={(template) => handleUpdateTemplate(selectedTemplate, template)}
          />
        </div>
      ) : (
        <>
          <SubscriptionAlertSettings
            settings={settings}
            onUpdate={(updates) => setSettings(prev => ({ ...prev, ...updates }))}
          />
          <SubscriptionAlertList
            alerts={alerts}
            onSendReminder={handleSendReminder}
          />
        </>
      )}
    </div>
  );
};