import { encode } from 'gpt-tokenizer';

// Constants for token management
export const TOKEN_LIMITS = {
  MAX_TOTAL_TOKENS: 16000, // Using gpt-3.5-turbo-16k model
  MAX_COMPLETION_TOKENS: 4000,
  SYSTEM_TOKENS: 500,
  CHUNK_SIZE: 4000
} as const;

export function truncateText(text: string): string {
  if (!text?.trim()) return '';

  const tokens = encode(text);
  const maxInputTokens = TOKEN_LIMITS.MAX_TOTAL_TOKENS - TOKEN_LIMITS.MAX_COMPLETION_TOKENS - TOKEN_LIMITS.SYSTEM_TOKENS;

  if (tokens.length <= maxInputTokens) {
    return text;
  }

  // Truncate while preserving sentence boundaries
  const sentences = text.match(/[^.!?]+[.!?]+/g) || [];
  let currentLength = 0;
  let result = [];

  for (const sentence of sentences) {
    const sentenceTokens = encode(sentence);
    if (currentLength + sentenceTokens.length <= maxInputTokens) {
      result.push(sentence);
      currentLength += sentenceTokens.length;
    } else {
      break;
    }
  }

  return result.join(' ').trim();
}

export function countTokens(text: string): number {
  return encode(text).length;
}

export function splitTextIntoChunks(text: string): string[] {
  const chunks: string[] = [];
  const sentences = text.match(/[^.!?]+[.!?]+/g) || [];
  let currentChunk = '';
  let currentTokens = 0;

  for (const sentence of sentences) {
    const sentenceTokens = encode(sentence);
    
    if (currentTokens + sentenceTokens.length > TOKEN_LIMITS.CHUNK_SIZE) {
      if (currentChunk) {
        chunks.push(currentChunk.trim());
        currentChunk = '';
        currentTokens = 0;
      }
    }
    
    currentChunk += sentence + ' ';
    currentTokens += sentenceTokens.length;
  }

  if (currentChunk) {
    chunks.push(currentChunk.trim());
  }

  return chunks;
}