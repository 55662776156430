export class OpenAIError extends Error {
  constructor(
    message: string,
    public code: string = 'unknown_error',
    public status?: number,
    public originalError?: any
  ) {
    super(message);
    this.name = 'OpenAIError';
  }

  static fromError(error: any): OpenAIError {
    if (error instanceof OpenAIError) {
      return error;
    }

    const response = error?.response?.data;
    const status = error?.response?.status;

    if (status === 401) {
      return new OpenAIError('Invalid API key', 'auth_error', status);
    }

    if (status === 429) {
      return new OpenAIError('Rate limit exceeded or quota exceeded. Please try again later.', 'rate_limit', status);
    }

    if (status === 500) {
      return new OpenAIError('OpenAI service error', 'server_error', status);
    }

    return new OpenAIError(
      response?.error?.message || error.message || 'An unknown error occurred',
      'api_error',
      status,
      error
    );
  }

  static isQuotaError(error: any): boolean {
    return error?.originalError?.error?.code === 'insufficient_quota';
  }
}