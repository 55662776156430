import { useState, useEffect } from 'react';
import { useFormStore } from '../store/formStore';

export const useTimer = () => {
  const { timerActive, timerStart, stopTimer } = useFormStore();
  const [elapsed, setElapsed] = useState('00:00');

  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    if (timerActive && timerStart) {
      intervalId = setInterval(() => {
        const now = Date.now();
        const diff = now - timerStart;
        const minutes = Math.floor(diff / 60000);
        const seconds = Math.floor((diff % 60000) / 1000);

        if (minutes >= 15) {
          stopTimer();
          setElapsed('00:00');
          return;
        }

        setElapsed(`${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`);
      }, 1000);
    } else {
      setElapsed('00:00');
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [timerActive, timerStart, stopTimer]);

  return elapsed;
};