import { useState, useCallback, useRef } from 'react';
import { OpenAIService } from '../services/openai/service';
import { aiResponseCache } from '../utils/aiResponseCache';

export const useClinicalSummary = () => {
  const [summary, setSummary] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const previousTextRef = useRef<string>('');

  const generateSummary = useCallback(async (text: string) => {
    if (!text?.trim() || text === previousTextRef.current) return;

    setIsLoading(true);
    setError(null);

    try {
      // Try cache first
      const cached = await aiResponseCache.getResponse(text, 'clinical_summary');
      if (cached) {
        setSummary(cached);
        previousTextRef.current = text;
        return;
      }

      // Generate new summary using clinical_summary prompt
      const response = await OpenAIService.analyzeClinicalNotes(
        text,
        'clinical_summary',
        []
      );

      if (response.summary) {
        setSummary(response.summary);
        previousTextRef.current = text;
        await aiResponseCache.setResponse(text, 'clinical_summary', response.summary);
      } else {
        throw new Error('No summary generated');
      }
    } catch (error: any) {
      console.error('Error generating summary:', error);
      setError(error?.message || 'Failed to generate summary');
      setSummary('');
    } finally {
      setIsLoading(false);
    }
  }, []);

  return {
    summary,
    isLoading,
    error,
    generateSummary
  };
};