import { CptCode } from '../types';

export const formatCptCode = (text: string): string => {
  const cleaned = text.replace(/[^a-zA-Z0-9]/g, '');
  const groups = cleaned.match(/.{1,5}/g) || [];
  return groups.join(' ');
};

export const extractCptCodes = (text: string): string[] => {
  if (!text) return [];
  const cptCodeRegex = /\b([A-Za-z]\d{4}|\d{5})\b/g;
  const matches = text.match(cptCodeRegex) || [];
  return Array.from(new Set(matches.map(code => code.toUpperCase())));
};

export const findDuplicateCptCodes = (
  code: string,
  lob: string,
  cptCodes: CptCode[]
): CptCode[] => {
  if (!code || !lob || !Array.isArray(cptCodes)) return [];

  return cptCodes.filter(
    cpt => 
      cpt.code.toUpperCase() === code.toUpperCase() && 
      cpt.lob.toLowerCase() === lob.toLowerCase()
  );
};

export const findMatchingGuideline = (
  code: string,
  lob: string,
  cptCodes: CptCode[]
): string | null => {
  if (!code || !lob || !Array.isArray(cptCodes)) return null;

  const duplicates = findDuplicateCptCodes(code, lob, cptCodes);
  
  if (duplicates.length === 0) return null;
  if (duplicates.length === 1) return duplicates[0].guideline;
  
  // If multiple matches, return all unique guidelines
  const uniqueGuidelines = Array.from(new Set(duplicates.map(d => d.guideline)));
  return uniqueGuidelines.join('\n\n');
};