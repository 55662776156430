import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Image as ImageIcon, Loader2 } from 'lucide-react';
import { createWorker } from 'tesseract.js';

interface ImageUploadProps {
  onTextExtracted: (text: string) => void;
}

const ImageUpload: React.FC<ImageUploadProps> = ({ onTextExtracted }) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const processImage = async (file: File) => {
    setIsProcessing(true);
    setError(null);

    try {
      const worker = await createWorker('eng');
      const { data: { text } } = await worker.recognize(file);
      await worker.terminate();
      
      if (text.trim()) {
        onTextExtracted(text.trim());
      } else {
        setError('No text could be extracted from the image');
      }
    } catch (err) {
      console.error('OCR error:', err);
      setError('Failed to process image. Please try again.');
    } finally {
      setIsProcessing(false);
    }
  };

  const onDrop = useCallback(async (acceptedFiles: File[]) => {
    for (const file of acceptedFiles) {
      await processImage(file);
    }
  }, [onTextExtracted]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'image/*': ['.png', '.jpg', '.jpeg', '.gif', '.bmp', '.webp']
    },
    multiple: true
  });

  return (
    <div
      {...getRootProps()}
      className={`p-3 rounded-full cursor-pointer transition-colors ${
        isDragActive
          ? 'bg-indigo-100 border-indigo-300'
          : isProcessing
          ? 'bg-gray-100 cursor-not-allowed'
          : 'bg-blue-500 hover:bg-blue-600'
      }`}
      title="Upload images with text"
    >
      <input {...getInputProps()} disabled={isProcessing} />
      {isProcessing ? (
        <Loader2 className="h-6 w-6 text-gray-500 animate-spin" />
      ) : (
        <ImageIcon className="h-6 w-6 text-white" />
      )}
      {error && (
        <div className="absolute bottom-full mb-2 left-1/2 transform -translate-x-1/2 bg-red-100 text-red-600 text-xs p-2 rounded whitespace-nowrap">
          {error}
        </div>
      )}
    </div>
  );
};

export default ImageUpload;