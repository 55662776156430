import { jwtDecode } from 'jwt-decode';
import { LocalStorageService } from '../LocalStorageService';
import { SessionManager } from './sessionManager';
import { AuthUser } from '../../types';

interface GoogleUser {
  email: string;
  name: string;
  picture: string;
  sub: string;
}

class GoogleAuthService {
  private readonly STORAGE_KEY = 'google_user';
  private initialized = false;
  private clientId = import.meta.env.VITE_GOOGLE_CLIENT_ID;

  async handleCredentialResponse(response: any): Promise<AuthUser> {
    try {
      const decoded = jwtDecode<GoogleUser>(response.credential);
      
      if (SessionManager.hasActiveSession()) {
        throw new Error('Another session is already active');
      }

      const user: AuthUser = {
        id: decoded.sub,
        email: decoded.email,
        name: decoded.name,
        picture: decoded.picture,
        isAdmin: false,
        active: true
      };

      await LocalStorageService.setData(this.STORAGE_KEY, [user]);
      SessionManager.createSession();
      
      return user;
    } catch (error) {
      console.error('Google auth error:', error);
      throw new Error('Failed to process Google authentication');
    }
  }

  async getCurrentUser(): Promise<AuthUser | null> {
    try {
      if (!SessionManager.hasActiveSession()) {
        return null;
      }
      const users = await LocalStorageService.getData(this.STORAGE_KEY);
      return users?.[0] || null;
    } catch (error) {
      console.warn('Error getting current user:', error);
      return null;
    }
  }

  async logout(): Promise<void> {
    try {
      SessionManager.clearSession();
      await LocalStorageService.clearData(this.STORAGE_KEY);
    } catch (error) {
      console.warn('Logout cleanup:', error);
      SessionManager.clearSession();
      await LocalStorageService.clearData(this.STORAGE_KEY);
    }
  }
}

export const googleAuthService = new GoogleAuthService();