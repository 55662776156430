import React from 'react';
import { useClinicalSummary } from '../../hooks/useClinicalSummary';
import ClinicalSummary from './ClinicalSummary';

interface SummarySectionProps {
  text: string;
  enabled?: boolean;
}

const SummarySection: React.FC<SummarySectionProps> = ({ 
  text,
  enabled = true
}) => {
  const { 
    summary, 
    keyFindings, 
    recommendations,
    isLoading, 
    error,
    generateSummary 
  } = useClinicalSummary();

  React.useEffect(() => {
    if (enabled && text) {
      generateSummary(text);
    }
  }, [text, enabled, generateSummary]);

  if (!enabled || !text) return null;

  return (
    <ClinicalSummary
      summary={summary}
      keyFindings={keyFindings}
      recommendations={recommendations}
      isLoading={isLoading}
      error={error}
    />
  );
};

export default SummarySection;