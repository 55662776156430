import React, { Suspense } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import Dashboard from '../pages/Dashboard';
import AdminLayout from '../pages/admin/AdminLayout';
import AdminPanel from '../pages/AdminPanel';
import SubscriberManagement from '../pages/admin/SubscriberManagement';
import BillingManagement from '../pages/admin/BillingManagement';
import SubscriptionAlerts from '../pages/admin/SubscriptionAlerts';
import UserAnalytics from '../pages/analytics/UserAnalytics';
import DocumentOCR from '../pages/DocumentOCR';
import Login from '../pages/Login';
import PrivateRoute from './PrivateRoute';
import AdminRoute from './AdminRoute';
import LoadingSpinner from '../components/LoadingSpinner';

const AppRoutes = () => {
  const { loading } = useAuth();

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-50">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <Suspense fallback={<LoadingSpinner />}>
      <Routes>
        {/* Redirect root to login */}
        <Route path="/" element={<Navigate to="/login" replace />} />
        
        {/* Public routes */}
        <Route path="/login" element={<Login />} />

        {/* Protected routes */}
        <Route
          path="/dashboard"
          element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          }
        />
        
        <Route
          path="/ocr"
          element={
            <PrivateRoute>
              <DocumentOCR />
            </PrivateRoute>
          }
        />
        
        <Route
          path="/analytics"
          element={
            <PrivateRoute>
              <UserAnalytics />
            </PrivateRoute>
          }
        />
        
        {/* Admin routes */}
        <Route
          path="/admin"
          element={
            <AdminRoute>
              <AdminLayout />
            </AdminRoute>
          }
        >
          <Route index element={<AdminPanel />} />
          <Route path="subscribers" element={<SubscriberManagement />} />
          <Route path="billing" element={<BillingManagement />} />
          <Route path="subscription-alerts" element={<SubscriptionAlerts />} />
        </Route>

        {/* Catch all route */}
        <Route path="*" element={<Navigate to="/login" replace />} />
      </Routes>
    </Suspense>
  );
};

export default AppRoutes;