import { useEffect } from 'react';
import { useAISettings } from '../store/aiSettingsStore';

export const useAISettingsInit = () => {
  const { initialized, settings, updateSettings } = useAISettings();

  useEffect(() => {
    if (!initialized || !settings.medicalHistoryPrompt) {
      // Only set default prompts if not initialized or missing
      updateSettings({
        medicalHistoryPrompt: settings.medicalHistoryPrompt || 'Write a clear, factual medical summary that addresses any specified focus areas. Include only documented medical information from the notes.',
        recommendationPrompt: settings.recommendationPrompt || 'Review clinical documentation against medical criteria. For each guideline, determine if criteria are met based on evidence in the notes.',
        clinicalSummaryPrompt: settings.clinicalSummaryPrompt || 'Provide a brief, factual summary of the patient\'s current medical situation. Focus only on documented information.'
      });
    }
  }, [initialized, settings, updateSettings]);

  return { initialized };
};