import React from 'react';
import { Play, Pause } from 'lucide-react';

interface TimerButtonProps {
  isActive: boolean;
  onClick: () => void;
}

const TimerButton: React.FC<TimerButtonProps> = ({ isActive, onClick }) => (
  <button
    onClick={onClick}
    className={`
      relative group
      px-6 py-3 rounded-xl
      bg-gradient-to-r from-blue-600/80 to-indigo-600/80
      hover:from-blue-500/90 hover:to-indigo-500/90
      backdrop-blur-lg
      border border-white/20
      transition-all duration-300
      hover:scale-105
      shadow-lg hover:shadow-indigo-500/25
      ${isActive ? 'animate-pulse shadow-indigo-500/50' : ''}
    `}
  >
    <div className="absolute inset-0 bg-gradient-to-r from-blue-400/20 to-indigo-400/20 rounded-xl blur-xl group-hover:blur-2xl transition-all duration-300" />
    <div className="relative">
      {isActive ? (
        <Pause className="h-5 w-5 text-white/90" />
      ) : (
        <Play className="h-5 w-5 text-white/90" />
      )}
    </div>
  </button>
);

export default TimerButton;