import React, { useState } from 'react';
import { AlertCircle, Save, X, Search } from 'lucide-react';
import { useData } from '../context/DataContext';
import Select from 'react-select';
import Fuse from 'fuse.js';

interface UnknownCptCodeModalProps {
  cptCode: string;
  suggestedDescription: string;
  onClose: () => void;
  onSave: (data: {
    code: string;
    description: string;
    lob?: string;
    guideline?: string;
  }) => void;
}

const UnknownCptCodeModal: React.FC<UnknownCptCodeModalProps> = ({
  cptCode,
  suggestedDescription,
  onClose,
  onSave
}) => {
  const { criteria } = useData();
  const [description, setDescription] = useState(suggestedDescription);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [selectedLOB, setSelectedLOB] = useState<{ value: string; label: string } | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedGuideline, setSelectedGuideline] = useState<string | null>(null);
  const [mode, setMode] = useState<'code-only' | 'with-guideline'>('code-only');

  const fuse = new Fuse(criteria.map(c => c.guideline), {
    threshold: 0.4,
    ignoreLocation: true,
    minMatchCharLength: 2
  });

  const filteredGuidelines = searchTerm
    ? fuse.search(searchTerm).map(result => result.item)
    : criteria.map(c => c.guideline);

  const handleSave = async () => {
    try {
      setSaving(true);
      setError(null);

      const data = {
        code: cptCode,
        description: description,
        ...(mode === 'with-guideline' && selectedLOB && selectedGuideline
          ? { lob: selectedLOB.value, guideline: selectedGuideline }
          : {})
      };

      await onSave(data);
      onClose();
    } catch (err) {
      console.error('Error saving procedure:', err);
      setError('Failed to save procedure. Please try again.');
    } finally {
      setSaving(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 max-w-lg w-full mx-4">
        <div className="flex justify-between items-center mb-4">
          <div className="flex items-center">
            <AlertCircle className="h-5 w-5 text-amber-500 mr-2" />
            <h2 className="text-xl font-semibold text-gray-900">New CPT Code Found</h2>
          </div>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <X className="h-5 w-5" />
          </button>
        </div>

        <div className="mb-4">
          <p className="text-sm text-gray-600">
            CPT Code <span className="font-medium">{cptCode}</span> was found with AI assistance.
            Would you like to add it to the database?
          </p>
        </div>

        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Description
            </label>
            <textarea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              rows={3}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
              placeholder="CPT code description..."
            />
          </div>

          <div className="flex items-center space-x-4">
            <button
              onClick={() => setMode('code-only')}
              className={`px-4 py-2 rounded-md ${
                mode === 'code-only'
                  ? 'bg-indigo-600 text-white'
                  : 'bg-gray-100 text-gray-700'
              }`}
            >
              Add Code Only
            </button>
            <button
              onClick={() => setMode('with-guideline')}
              className={`px-4 py-2 rounded-md ${
                mode === 'with-guideline'
                  ? 'bg-indigo-600 text-white'
                  : 'bg-gray-100 text-gray-700'
              }`}
            >
              Link to Guideline
            </button>
          </div>

          {mode === 'with-guideline' && (
            <>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Line of Business
                </label>
                <Select
                  value={selectedLOB}
                  onChange={setSelectedLOB}
                  options={[
                    { value: 'Medicare', label: 'Medicare' },
                    { value: 'Commercial', label: 'Commercial' },
                    { value: 'Medicaid', label: 'Medicaid' }
                  ]}
                  className="basic-select"
                  classNamePrefix="select"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Search Guidelines
                </label>
                <div className="relative">
                  <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 h-5 w-5" />
                  <input
                    type="text"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="w-full pl-10 pr-4 py-2 border rounded-lg focus:ring-2 focus:ring-indigo-500"
                    placeholder="Search guidelines..."
                  />
                </div>
              </div>

              <div className="max-h-40 overflow-y-auto border rounded-lg">
                {filteredGuidelines.map((guideline, index) => (
                  <div
                    key={index}
                    className={`p-2 cursor-pointer hover:bg-gray-50 ${
                      selectedGuideline === guideline ? 'bg-indigo-50' : ''
                    }`}
                    onClick={() => setSelectedGuideline(guideline)}
                  >
                    <p className="text-sm">{guideline}</p>
                  </div>
                ))}
              </div>
            </>
          )}
        </div>

        {error && (
          <div className="mt-4 p-3 bg-red-50 text-red-700 rounded-md text-sm">
            {error}
          </div>
        )}

        <div className="mt-6 flex justify-end space-x-3">
          <button
            onClick={onClose}
            className="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 rounded-md hover:bg-gray-200"
          >
            Cancel
          </button>
          <button
            onClick={handleSave}
            disabled={saving || !description.trim() || (mode === 'with-guideline' && (!selectedLOB || !selectedGuideline))}
            className="flex items-center px-4 py-2 text-sm font-medium text-white bg-indigo-600 rounded-md hover:bg-indigo-700 disabled:opacity-50"
          >
            {saving ? (
              <>
                <span className="animate-spin rounded-full h-4 w-4 border-b-2 border-white mr-2" />
                Saving...
              </>
            ) : (
              <>
                <Save className="h-4 w-4 mr-2" />
                Save to Database
              </>
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default UnknownCptCodeModal;