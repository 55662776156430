import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import './index.css';
import { initializeSystem } from './lib/core/initialization';

const renderApp = async () => {
  const rootElement = document.getElementById('root');
  if (!rootElement) {
    throw new Error('Root element not found');
  }

  try {
    // Initialize system before rendering
    await initializeSystem();

    const root = createRoot(rootElement);
    root.render(
      <StrictMode>
        <App />
      </StrictMode>
    );
  } catch (error) {
    console.error('Application initialization failed:', error);
    
    rootElement.innerHTML = `
      <div class="min-h-screen bg-gray-50 flex items-center justify-center p-4">
        <div class="max-w-md w-full bg-white rounded-lg shadow-xl p-6 text-center">
          <div class="text-red-500 mb-4">
            <svg class="h-12 w-12 mx-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"/>
            </svg>
          </div>
          <h1 class="text-xl font-bold text-gray-900 mb-2">Unable to Start Application</h1>
          <p class="text-gray-600">We're having trouble starting the application. Please try:</p>
          <ul class="mt-4 text-left text-gray-600 space-y-2">
            <li>• Refreshing the page</li>
            <li>• Clearing your browser cache</li>
            <li>• Trying again in a few minutes</li>
          </ul>
          <button 
            onclick="window.location.reload()" 
            class="mt-6 px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 transition-colors"
          >
            Refresh Page
          </button>
        </div>
      </div>
    `;
  }
};

// Start the application
renderApp().catch(error => {
  console.error('Fatal application error:', error);
});