import localforage from 'localforage';

// Configure stores with optimized settings
const stores = {
  auth_user: localforage.createInstance({
    name: 'um_system_auth',
    storeName: 'auth',
    driver: [localforage.INDEXEDDB, localforage.LOCALSTORAGE]
  }),
  cptCodes: localforage.createInstance({
    name: 'um_system_cptCodes',
    storeName: 'cptCodes',
    driver: [localforage.INDEXEDDB]
  }),
  criteria: localforage.createInstance({
    name: 'um_system_criteria',
    storeName: 'criteria',
    driver: [localforage.INDEXEDDB]
  }),
  procedures: localforage.createInstance({
    name: 'um_system_procedures',
    storeName: 'procedures',
    driver: [localforage.INDEXEDDB]
  }),
  aiSettings: localforage.createInstance({
    name: 'um_system_ai',
    storeName: 'settings',
    driver: [localforage.INDEXEDDB]
  })
};

export class LocalStorageService {
  private static initialized = false;
  private static initializationPromise: Promise<void> | null = null;

  private static async initialize() {
    if (this.initialized) return;
    if (this.initializationPromise) return this.initializationPromise;

    this.initializationPromise = (async () => {
      try {
        await Promise.all(
          Object.values(stores).map(async (store) => {
            const data = await store.getItem('data');
            if (!data) {
              await store.setItem('data', []);
            }
          })
        );
        this.initialized = true;
      } catch (error) {
        console.error('Error initializing stores:', error);
        throw error;
      } finally {
        this.initializationPromise = null;
      }
    })();

    return this.initializationPromise;
  }

  static async getData(key: string): Promise<any[]> {
    try {
      await this.initialize();

      const store = stores[key as keyof typeof stores];
      if (!store) {
        console.warn(`Invalid store key: ${key}, returning empty array`);
        return [];
      }

      const data = await store.getItem('data');
      return data ? JSON.parse(JSON.stringify(data)) : [];
    } catch (error) {
      console.warn(`Error getting ${key} from storage:`, error);
      return [];
    }
  }

  static async setData(key: string, data: any[]): Promise<void> {
    try {
      await this.initialize();

      const store = stores[key as keyof typeof stores];
      if (!store) {
        throw new Error(`Invalid store key: ${key}`);
      }

      await store.setItem('data', JSON.parse(JSON.stringify(data)));
    } catch (error) {
      console.error(`Error setting ${key} in storage:`, error);
      throw error;
    }
  }

  static async clearData(key: string): Promise<void> {
    try {
      await this.initialize();

      const store = stores[key as keyof typeof stores];
      if (!store) {
        throw new Error(`Invalid store key: ${key}`);
      }

      await store.clear();
      await store.setItem('data', []);
    } catch (error) {
      console.error(`Error clearing ${key} from storage:`, error);
      throw error;
    }
  }
}