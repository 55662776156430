import React, { useState, useCallback } from 'react';
import { AlertCircle, Loader2, X, Trash2, Save, Edit2 } from 'lucide-react';
import { useData } from '../../context/DataContext';
import { OpenAIService } from '../../services/openai/service';
import { extractCptCodes } from '../../utils/cptCodeUtils';

const GuidelineProcessor: React.FC = () => {
  const { cptCodes, criteria, procedures, updateData } = useData();
  const [guidelineText, setGuidelineText] = useState('');
  const [selectedLOB, setSelectedLOB] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showDuplicateConfirmation, setShowDuplicateConfirmation] = useState(false);
  const [duplicates, setDuplicates] = useState<Array<{ guideline: string; similarity: number }>>([]);
  const [processedData, setProcessedData] = useState<{
    title: string;
    cptCodes: Array<{ code: string; description: string }>;
  } | null>(null);
  const [isEditingTitle, setIsEditingTitle] = useState(false);

  const handleProcess = async () => {
    if (!guidelineText.trim()) {
      setError('Please enter guideline text');
      return;
    }

    setLoading(true);
    setError(null);

    try {
      // Extract title from first line
      const lines = guidelineText.trim().split('\n');
      const extractedTitle = lines[0].trim();
      
      // Extract CPT codes
      const extractedCodes = extractCptCodes(guidelineText);
      
      // Get descriptions for CPT codes
      const codeDescriptions = await Promise.all(
        extractedCodes.map(async code => {
          const procedure = procedures.find(p => p.code === code);
          if (procedure) {
            return { code, description: procedure.description };
          }
          try {
            const response = await OpenAIService.analyzeClinicalNotes(code, 'get_description', []);
            return { code, description: response.description || '' };
          } catch (err) {
            console.error(`Error getting description for code ${code}:`, err);
            return { code, description: '' };
          }
        })
      );

      setProcessedData({
        title: extractedTitle,
        cptCodes: codeDescriptions
      });
      setShowConfirmation(true);

    } catch (err) {
      console.error('Error processing guideline:', err);
      setError('Failed to process guideline. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteCptCode = useCallback((codeToDelete: string) => {
    if (processedData) {
      setProcessedData({
        ...processedData,
        cptCodes: processedData.cptCodes.filter(c => c.code !== codeToDelete)
      });
    }
  }, [processedData]);

  const handleTitleEdit = useCallback((newTitle: string) => {
    if (processedData) {
      setProcessedData({
        ...processedData,
        title: newTitle
      });
    }
    setIsEditingTitle(false);
  }, [processedData]);

  return (
    <div className="space-y-6">
      <div>
        <label className="block text-sm font-medium text-white mb-2">
          Line of Business (Optional)
        </label>
        <select
          value={selectedLOB}
          onChange={(e) => setSelectedLOB(e.target.value)}
          className="input-field w-full"
        >
          <option value="">Select LOB (Optional)</option>
          <option value="Medicare">Medicare</option>
          <option value="Commercial">Commercial</option>
          <option value="Medicaid">Medicaid</option>
        </select>
      </div>

      <div>
        <label className="block text-sm font-medium text-white mb-2">
          Guideline Text
        </label>
        <textarea
          value={guidelineText}
          onChange={(e) => setGuidelineText(e.target.value)}
          rows={8}
          className="input-field w-full resize-y"
          placeholder="Paste guideline text here..."
        />
      </div>

      {error && (
        <div className="p-4 bg-red-500/20 rounded-xl flex items-start">
          <AlertCircle className="h-5 w-5 text-red-400 mr-2 mt-0.5" />
          <span className="text-sm text-red-300">{error}</span>
        </div>
      )}

      {success && (
        <div className="p-4 bg-green-500/20 rounded-xl">
          <span className="text-sm text-green-300">{success}</span>
        </div>
      )}

      <div className="flex justify-end">
        <button
          onClick={handleProcess}
          disabled={loading || !guidelineText.trim()}
          className="button-primary"
        >
          {loading ? 'Processing...' : 'Process Guideline'}
        </button>
      </div>

      {showConfirmation && processedData && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 max-w-lg w-full mx-4">
            <h3 className="text-lg font-medium mb-4">Confirm Guideline</h3>
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Title
                </label>
                {isEditingTitle ? (
                  <div className="flex gap-2">
                    <input
                      type="text"
                      defaultValue={processedData.title}
                      className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-indigo-500"
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          handleTitleEdit(e.currentTarget.value);
                        }
                      }}
                      onBlur={(e) => handleTitleEdit(e.target.value)}
                    />
                    <button
                      onClick={() => setIsEditingTitle(false)}
                      className="p-2 text-gray-500 hover:text-gray-700"
                    >
                      <X className="h-5 w-5" />
                    </button>
                  </div>
                ) : (
                  <div className="flex justify-between items-center">
                    <p className="text-sm text-gray-900">{processedData.title}</p>
                    <button
                      onClick={() => setIsEditingTitle(true)}
                      className="p-2 text-gray-500 hover:text-gray-700"
                    >
                      <Edit2 className="h-5 w-5" />
                    </button>
                  </div>
                )}
              </div>

              {processedData.cptCodes.length > 0 && (
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    CPT Codes
                  </label>
                  <div className="space-y-2">
                    {processedData.cptCodes.map((code, index) => (
                      <div key={index} className="flex justify-between items-center bg-gray-50 p-2 rounded-lg">
                        <div>
                          <span className="font-medium">{code.code}:</span>{' '}
                          <span className="text-gray-600">{code.description}</span>
                        </div>
                        <button
                          onClick={() => handleDeleteCptCode(code.code)}
                          className="p-1 text-red-600 hover:text-red-900"
                          title="Delete CPT code"
                        >
                          <Trash2 className="h-4 w-4" />
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>

            <div className="mt-6 flex justify-end space-x-3">
              <button
                onClick={() => setShowConfirmation(false)}
                className="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 rounded-lg hover:bg-gray-200"
              >
                Cancel
              </button>
              <button
                onClick={async () => {
                  try {
                    // Add to criteria collection
                    const newCriteria = {
                      guideline: processedData.title,
                      criteria: guidelineText,
                      timestamp: new Date()
                    };
                    await updateData('criteria', [...criteria, newCriteria]);

                    // Add CPT codes if they don't exist
                    if (selectedLOB && processedData.cptCodes.length > 0) {
                      const newCptCodes = processedData.cptCodes
                        .filter(code => !cptCodes.some(c => c.code === code.code))
                        .map(code => ({
                          code: code.code,
                          lob: selectedLOB,
                          guideline: processedData.title
                        }));

                      if (newCptCodes.length > 0) {
                        await updateData('cptCodes', [...cptCodes, ...newCptCodes]);
                      }
                    }

                    setSuccess('Guideline processed and saved successfully');
                    setGuidelineText('');
                    setShowConfirmation(false);
                  } catch (err) {
                    console.error('Error saving guideline:', err);
                    setError('Failed to save guideline. Please try again.');
                  }
                }}
                className="px-4 py-2 text-sm font-medium text-white bg-indigo-600 rounded-lg hover:bg-indigo-700"
              >
                Save Guideline
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default GuidelineProcessor;