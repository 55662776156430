import React from 'react';
import { Settings } from 'lucide-react';

interface SubscriptionAlertSettingsProps {
  settings: {
    paymentReminderDays: number;
    subscriptionEndingDays: number;
    gracePeriodDays: number;
  };
  onUpdate: (settings: Partial<{
    paymentReminderDays: number;
    subscriptionEndingDays: number;
    gracePeriodDays: number;
  }>) => void;
}

export const SubscriptionAlertSettings: React.FC<SubscriptionAlertSettingsProps> = ({
  settings,
  onUpdate
}) => {
  return (
    <div className="bg-white rounded-lg shadow-sm p-6">
      <div className="flex items-center gap-2 mb-6">
        <Settings className="h-5 w-5 text-indigo-600" />
        <h3 className="text-lg font-medium">Alert Settings</h3>
      </div>

      <div className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Payment Reminder Days
          </label>
          <input
            type="number"
            min="1"
            max="30"
            value={settings.paymentReminderDays}
            onChange={(e) => onUpdate({ paymentReminderDays: parseInt(e.target.value) })}
            className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-indigo-500"
          />
          <p className="mt-1 text-sm text-gray-500">
            Days before payment due date to send reminder
          </p>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Subscription Ending Warning Days
          </label>
          <input
            type="number"
            min="1"
            max="30"
            value={settings.subscriptionEndingDays}
            onChange={(e) => onUpdate({ subscriptionEndingDays: parseInt(e.target.value) })}
            className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-indigo-500"
          />
          <p className="mt-1 text-sm text-gray-500">
            Days before subscription ends to send warning
          </p>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Grace Period Days
          </label>
          <input
            type="number"
            min="0"
            max="14"
            value={settings.gracePeriodDays}
            onChange={(e) => onUpdate({ gracePeriodDays: parseInt(e.target.value) })}
            className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-indigo-500"
          />
          <p className="mt-1 text-sm text-gray-500">
            Days after payment failure before deactivating account
          </p>
        </div>
      </div>
    </div>
  );
};