import React, { useCallback } from 'react';
import { useFormStore } from '../store/formStore';
import { useReviewStore } from '../store/reviewStore';
import { useTimer } from '../hooks/useTimer';
import TimerButton from './timer/TimerButton';
import TimerDisplay from './timer/TimerDisplay';
import { aiResponseCache } from '../services/AIResponseCache';

const ReviewTimer: React.FC = () => {
  const { 
    timerActive, 
    startTimer, 
    stopTimer, 
    clearForm
  } = useFormStore();
  const { incrementReviews } = useReviewStore();
  const elapsed = useTimer();

  const handleTimerClick = useCallback(() => {
    if (timerActive) {
      stopTimer();
      incrementReviews();
      
      // Clear form data and cache
      clearForm();
      aiResponseCache.clearCache();
    } else {
      startTimer();
    }
  }, [timerActive, startTimer, stopTimer, incrementReviews, clearForm]);

  return (
    <div className="flex items-center space-x-3">
      <TimerButton isActive={timerActive} onClick={handleTimerClick} />
      <TimerDisplay elapsed={elapsed} />
    </div>
  );
};

export default ReviewTimer;