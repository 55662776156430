import React, { useCallback } from 'react';
import { AlertCircle } from 'lucide-react';
import { createWorker } from 'tesseract.js';
import DictationButton from './DictationButton';
import ImageUpload from './ImageUpload';
import SummarySection from './summary/SummarySection';

interface MedicalHistoryInputProps {
  clinicalNotes: string;
  aiFocus: string;
  onClinicalNotesChange: (notes: string) => void;
  onAIFocusChange: (focus: string) => void;
}

const MedicalHistoryInput: React.FC<MedicalHistoryInputProps> = ({
  clinicalNotes,
  aiFocus,
  onClinicalNotesChange,
  onAIFocusChange
}) => {
  const handlePaste = async (e: React.ClipboardEvent<HTMLTextAreaElement>) => {
    const items = e.clipboardData?.items;
    if (!items) return;

    for (const item of Array.from(items)) {
      if (item.type.indexOf('image') !== -1) {
        e.preventDefault();
        const file = item.getAsFile();
        if (file) {
          await handleImageFile(file);
        }
      }
    }
  };

  const handleDrop = async (e: React.DragEvent<HTMLTextAreaElement>) => {
    e.preventDefault();
    
    const files = Array.from(e.dataTransfer.files);
    const imageFiles = files.filter(file => file.type.startsWith('image/'));
    
    for (const file of imageFiles) {
      await handleImageFile(file);
    }
  };

  const handleImageFile = async (file: File) => {
    try {
      const worker = await createWorker('eng');
      const { data: { text } } = await worker.recognize(file);
      await worker.terminate();
      
      if (text.trim()) {
        onClinicalNotesChange(prev => prev + '\n' + text.trim());
      }
    } catch (error) {
      console.error('OCR error:', error);
    }
  };

  const handleDictationResult = useCallback((text: string) => {
    onClinicalNotesChange(prev => prev + ' ' + text);
  }, [onClinicalNotesChange]);

  const handleImageUpload = useCallback((text: string) => {
    onClinicalNotesChange(prev => prev + ' ' + text);
  }, [onClinicalNotesChange]);

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
      <div>
        <label className="block text-sm font-medium text-white mb-2">
          Clinical Notes
        </label>
        <div className="relative">
          <textarea
            value={clinicalNotes}
            onChange={(e) => onClinicalNotesChange(e.target.value)}
            onPaste={handlePaste}
            onDrop={handleDrop}
            rows={10}
            className="w-full px-3 py-2 bg-white/10 border border-white/20 rounded-lg text-white placeholder-white/50 focus:ring-2 focus:ring-indigo-500 resize-y transition-colors duration-200"
            placeholder="Enter, paste, or drag images here..."
            style={{ minHeight: '200px' }}
          />
          <div className="absolute bottom-2 right-2 flex items-center space-x-2">
            <DictationButton onTextReceived={handleDictationResult} />
            <ImageUpload onTextExtracted={handleImageUpload} />
          </div>
        </div>
        <SummarySection text={clinicalNotes} />
      </div>
      
      <div>
        <label className="block text-sm font-medium text-white mb-2">
          AI Focus Area (Optional)
          <span className="ml-2 text-white/60 text-xs">
            Guide AI on specific aspects to analyze
          </span>
        </label>
        <textarea
          value={aiFocus}
          onChange={(e) => onAIFocusChange(e.target.value)}
          rows={4}
          className="w-full px-3 py-2 bg-white/10 border border-white/20 rounded-lg text-white placeholder-white/50 focus:ring-2 focus:ring-indigo-500 mb-2"
          placeholder="E.g., 'Focus on cardiovascular history' or 'Analyze recent surgical procedures'"
        />
        <div className="bg-white/10 rounded-lg p-4 text-sm text-white/80">
          <div className="flex items-center mb-2">
            <AlertCircle className="h-4 w-4 mr-2 text-indigo-400" />
            <span className="font-medium">Tips for AI Focus:</span>
          </div>
          <ul className="list-disc list-inside space-y-1 ml-6">
            <li>Specify conditions or systems to prioritize</li>
            <li>Highlight specific time periods</li>
            <li>Request focus on particular treatments or procedures</li>
            <li>Ask for emphasis on specific symptoms or outcomes</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default MedicalHistoryInput;