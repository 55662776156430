import React, { useState, useCallback, useRef } from 'react';
import { Upload, AlertCircle, Loader2 } from 'lucide-react';
import { useDropzone } from 'react-dropzone';
import { useData } from '../../context/DataContext';
import { DataService } from '../../services/DataService';
import DiagnosticButton from './DiagnosticButton';

interface FileUploaderProps {
  type: string;
}

const FileUploader: React.FC<FileUploaderProps> = ({ type }) => {
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const { updateData } = useData();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const onDrop = useCallback(async (acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    if (!file) return;

    setLoading(true);
    setError(null);
    setSuccess(null);
    setProgress(0);

    try {
      console.log('Processing file:', file.name);
      setProgress(25);
      
      const data = await DataService.parseFile(file);
      console.log('Parsed data:', { count: data.length, sample: data[0] });
      
      if (!data.length) {
        throw new Error('No valid data found in file');
      }
      
      setProgress(50);
      console.log('Updating data store...');
      
      await updateData(type, data);
      
      setProgress(100);
      setSuccess(`Successfully uploaded ${data.length} records`);
      console.log('Upload complete');
      
    } catch (err: any) {
      console.error('Upload failed:', err);
      setError(err.message || 'Upload failed');
    } finally {
      setLoading(false);
    }
  }, [type, updateData]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'text/csv': ['.csv'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
      'application/vnd.ms-excel': ['.xls']
    },
    multiple: false
  });

  return (
    <div className="space-y-4">
      <div
        {...getRootProps()}
        className={`p-4 border-2 border-dashed rounded-lg cursor-pointer transition-colors ${
          isDragActive
            ? 'border-indigo-300 bg-indigo-50'
            : 'border-gray-300 hover:border-indigo-300'
        }`}
      >
        <input {...getInputProps()} />
        <div className="flex items-center justify-center">
          {loading ? (
            <Loader2 className="h-6 w-6 text-indigo-500 animate-spin" />
          ) : (
            <Upload className="h-6 w-6 text-indigo-500" />
          )}
          <span className="ml-2 text-sm text-gray-600">
            {loading ? 'Uploading...' : 'Drop file here or click to upload'}
          </span>
        </div>
      </div>

      {(loading || progress > 0) && (
        <div className="bg-gray-50 rounded-md p-4">
          <div className="w-full bg-gray-200 rounded-full h-2">
            <div
              className="bg-indigo-600 h-2 rounded-full transition-all duration-300"
              style={{ width: `${progress}%` }}
            />
          </div>
          <p className="text-sm text-gray-600 mt-2">
            {loading ? 'Processing...' : `${progress}% Complete`}
          </p>
        </div>
      )}

      {error && (
        <div className="bg-red-50 text-red-700 p-4 rounded-md text-sm">
          {error}
        </div>
      )}

      {success && (
        <div className="bg-green-50 text-green-700 p-4 rounded-md text-sm">
          {success}
        </div>
      )}

      <DiagnosticButton onFileSelect={() => {}} />
    </div>
  );
};

export default FileUploader;