import React from 'react';
import { Clock } from 'lucide-react';

interface TimerDisplayProps {
  elapsed: string;
}

const TimerDisplay: React.FC<TimerDisplayProps> = ({ elapsed }) => (
  <div className="flex items-center space-x-2 bg-white/10 px-4 py-2 rounded-lg backdrop-blur-sm">
    <Clock className="h-5 w-5 text-white/90" />
    <span className="font-mono font-bold text-transparent bg-clip-text bg-gradient-to-r from-white to-blue-100">
      {elapsed}
    </span>
  </div>
);

export default TimerDisplay;