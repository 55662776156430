import React from 'react';
import { Play, Square } from 'lucide-react';

interface SpeechButtonProps {
  isPlaying: boolean;
  isMuted: boolean;
  onStart: () => void;
  onStop: () => void;
}

export const SpeechButton: React.FC<SpeechButtonProps> = ({
  isPlaying,
  isMuted,
  onStart,
  onStop
}) => {
  if (isPlaying) {
    return (
      <button
        onClick={onStop}
        className="p-2 text-red-600 hover:text-red-700 rounded-full hover:bg-red-50 transition-colors"
        title="Stop Reading"
      >
        <Square className="h-5 w-5" />
      </button>
    );
  }

  return (
    <button
      onClick={onStart}
      disabled={isMuted}
      className={`p-2 rounded-full transition-colors ${
        isMuted 
          ? 'text-gray-400 cursor-not-allowed'
          : 'text-indigo-600 hover:text-indigo-700 hover:bg-indigo-50'
      }`}
      title={isMuted ? 'Audio is muted' : 'Start Reading'}
    >
      <Play className="h-5 w-5" />
    </button>
  );
};