import React, { useState } from 'react';
import { Save } from 'lucide-react';

interface EmailTemplate {
  subject: string;
  body: string;
}

interface EmailTemplateEditorProps {
  type: 'payment_failed' | 'subscription_ending' | 'subscription_canceled';
  template: EmailTemplate;
  onSave: (template: EmailTemplate) => void;
}

export const EmailTemplateEditor: React.FC<EmailTemplateEditorProps> = ({
  type,
  template,
  onSave
}) => {
  const [subject, setSubject] = useState(template.subject);
  const [body, setBody] = useState(template.body);
  const [saving, setSaving] = useState(false);

  const handleSave = async () => {
    setSaving(true);
    try {
      await onSave({ subject, body });
    } finally {
      setSaving(false);
    }
  };

  const getAvailableVariables = () => {
    const commonVars = ['{name}', '{email}'];
    switch (type) {
      case 'payment_failed':
        return [...commonVars, '{amount}', '{dueDate}', '{paymentLink}'];
      case 'subscription_ending':
        return [...commonVars, '{plan}', '{endDate}', '{amount}', '{renewalLink}'];
      case 'subscription_canceled':
        return [...commonVars, '{endDate}', '{reactivateLink}'];
      default:
        return commonVars;
    }
  };

  return (
    <div className="space-y-4">
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Subject Line
        </label>
        <input
          type="text"
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
          className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-indigo-500"
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Email Body
        </label>
        <textarea
          value={body}
          onChange={(e) => setBody(e.target.value)}
          rows={10}
          className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-indigo-500 font-mono text-sm"
        />
      </div>

      <div className="bg-gray-50 p-4 rounded-lg">
        <h4 className="text-sm font-medium text-gray-700 mb-2">Available Variables:</h4>
        <div className="flex flex-wrap gap-2">
          {getAvailableVariables().map((variable) => (
            <span
              key={variable}
              className="px-2 py-1 bg-gray-200 rounded text-sm font-mono cursor-pointer hover:bg-gray-300"
              title="Click to copy"
              onClick={() => {
                navigator.clipboard.writeText(variable);
                const el = document.createElement('div');
                el.className = 'fixed bottom-4 right-4 bg-black text-white px-4 py-2 rounded-lg';
                el.textContent = 'Variable copied!';
                document.body.appendChild(el);
                setTimeout(() => el.remove(), 2000);
              }}
            >
              {variable}
            </span>
          ))}
        </div>
      </div>

      <div className="flex justify-end">
        <button
          onClick={handleSave}
          disabled={saving}
          className="flex items-center px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 disabled:opacity-50"
        >
          {saving ? (
            <>
              <span className="animate-spin rounded-full h-4 w-4 border-b-2 border-white mr-2" />
              Saving...
            </>
          ) : (
            <>
              <Save className="h-4 w-4 mr-2" />
              Save Template
            </>
          )}
        </button>
      </div>
    </div>
  );
};