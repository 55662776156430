import React, { useState } from 'react';
import { Save, AlertCircle } from 'lucide-react';
import { useAISettings } from '../../store/aiSettingsStore';

const AISettingsTab = () => {
  const { settings, updateSettings } = useAISettings();
  const [localSettings, setLocalSettings] = useState(settings);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);

  const handleSave = async () => {
    try {
      setSaving(true);
      setError(null);
      setSuccess(null);

      // Validate all required fields
      if (!localSettings.medicalHistoryPrompt?.trim()) {
        throw new Error('Medical History prompt is required');
      }
      if (!localSettings.recommendationPrompt?.trim()) {
        throw new Error('Recommendation prompt is required');
      }
      if (!localSettings.clinicalSummaryPrompt?.trim()) {
        throw new Error('Clinical Summary prompt is required');
      }

      updateSettings(localSettings);
      setSuccess('Settings saved successfully!');
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to save settings');
      console.error('Settings update error:', err);
    } finally {
      setSaving(false);
    }
  };

  return (
    <div className="space-y-6">
      <div className="bg-white rounded-lg shadow-sm p-6">
        <h2 className="text-lg font-semibold text-gray-900 mb-4">AI Prompt Settings</h2>
        
        <div className="space-y-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Medical History Prompt
              <span className="ml-2 text-sm text-gray-500">
                Instructions for medical history analysis
              </span>
            </label>
            <textarea
              value={localSettings.medicalHistoryPrompt}
              onChange={(e) => setLocalSettings(prev => ({
                ...prev,
                medicalHistoryPrompt: e.target.value
              }))}
              rows={6}
              className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-indigo-500"
              placeholder="Enter instructions for analyzing medical history..."
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Clinical Summary Prompt
              <span className="ml-2 text-sm text-gray-500">
                Instructions for generating clinical summaries
              </span>
            </label>
            <textarea
              value={localSettings.clinicalSummaryPrompt}
              onChange={(e) => setLocalSettings(prev => ({
                ...prev,
                clinicalSummaryPrompt: e.target.value
              }))}
              rows={6}
              className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-indigo-500"
              placeholder="Enter instructions for generating clinical summaries..."
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Recommendation Prompt
              <span className="ml-2 text-sm text-gray-500">
                Instructions for generating recommendations
              </span>
            </label>
            <textarea
              value={localSettings.recommendationPrompt}
              onChange={(e) => setLocalSettings(prev => ({
                ...prev,
                recommendationPrompt: e.target.value
              }))}
              rows={6}
              className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-indigo-500"
              placeholder="Enter instructions for generating recommendations..."
            />
          </div>

          {error && (
            <div className="p-4 bg-red-50 rounded-md flex items-center">
              <AlertCircle className="h-5 w-5 text-red-400 mr-2" />
              <span className="text-sm text-red-700">{error}</span>
            </div>
          )}

          {success && (
            <div className="p-4 bg-green-50 rounded-md">
              <span className="text-sm text-green-700">{success}</span>
            </div>
          )}

          <div className="flex justify-end">
            <button
              onClick={handleSave}
              disabled={saving}
              className="flex items-center px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 disabled:opacity-50"
            >
              {saving ? (
                <>
                  <span className="animate-spin rounded-full h-4 w-4 border-b-2 border-white mr-2" />
                  Saving...
                </>
              ) : (
                <>
                  <Save className="h-4 w-4 mr-2" />
                  Save Changes
                </>
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AISettingsTab;