import OpenAI from 'openai';
import { openAISettings } from './config/settings';

if (!openAISettings.apiKey) {
  console.warn('OpenAI API key is not configured. AI features will be disabled.');
}

export const openai = new OpenAI({
  apiKey: openAISettings.apiKey,
  baseURL: openAISettings.baseURL,
  timeout: openAISettings.timeout,
  maxRetries: openAISettings.retries,
  dangerouslyAllowBrowser: true
});