import { generateCompletion } from './api';
import { OpenAIError } from './errors';
import { AIResponse } from './types';
import { useAISettings } from '../../store/aiSettingsStore';

export class OpenAIService {
  static async analyzeClinicalNotes(
    text: string,
    type: 'medical_history' | 'recommendation' | 'clinical_summary',
    guidelines: string[] = []
  ): Promise<AIResponse> {
    if (!text?.trim()) {
      throw new OpenAIError('Text input is required', 'invalid_input');
    }

    try {
      // Get settings from store
      const { settings, initialized } = useAISettings.getState();
      
      if (!initialized) {
        throw new OpenAIError('AI settings not initialized', 'not_initialized');
      }

      // Get the appropriate prompt based on type
      let prompt;
      switch (type) {
        case 'medical_history':
          prompt = settings.medicalHistoryPrompt;
          break;
        case 'recommendation':
          prompt = settings.recommendationPrompt;
          break;
        case 'clinical_summary':
          prompt = settings.clinicalSummaryPrompt;
          break;
        default:
          throw new OpenAIError(`Invalid prompt type: ${type}`, 'invalid_type');
      }

      // Validate prompt exists
      if (!prompt?.trim()) {
        throw new OpenAIError(
          `${type} prompt not configured. Please configure prompts in Admin Panel.`,
          'missing_prompt'
        );
      }

      // Create system and user prompts
      const systemPrompt = `You are a medical documentation assistant. ${prompt}`;
      const userPrompt = guidelines.length > 0
        ? `${text}\n\nGuidelines to consider:\n${guidelines.join('\n')}`
        : text;

      // Generate completion
      const content = await generateCompletion(text, systemPrompt, guidelines);

      return {
        summary: content.trim(),
        review: content.trim(),
        description: content.trim()
      };

    } catch (error) {
      console.error('OpenAI Service Error:', error);
      throw error;
    }
  }
}