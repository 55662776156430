import React from 'react';
import { X } from 'lucide-react';

interface ChatHeaderProps {
  onClose: () => void;
}

const ChatHeader: React.FC<ChatHeaderProps> = ({ onClose }) => {
  return (
    <div className="flex justify-between items-center p-4 border-b">
      <h3 className="text-lg font-medium">AI Assistant</h3>
      <button
        onClick={onClose}
        className="text-gray-500 hover:text-gray-700"
        aria-label="Close chat"
      >
        <X className="h-5 w-5" />
      </button>
    </div>
  );
};

export default ChatHeader;