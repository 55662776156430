import { LocalStorageService } from './LocalStorageService';

interface EmailTemplate {
  subject: string;
  body: string;
}

interface EmailTemplates {
  [key: string]: EmailTemplate;
}

const DEFAULT_TEMPLATES: EmailTemplates = {
  payment_failed: {
    subject: 'Action Required: Payment Failed',
    body: `Dear {name},

We were unable to process your recent payment for your UM Note Taker subscription.

Payment Details:
- Amount: ${'{amount}'}
- Due Date: {dueDate}

To avoid any interruption in service, please update your payment information by clicking the link below:
{paymentLink}

If you need assistance, please don't hesitate to contact our support team.

Best regards,
UM Note Taker Team`
  },
  subscription_ending: {
    subject: 'Your Subscription is Ending Soon',
    body: `Dear {name},

Your UM Note Taker subscription will end on {endDate}.

To continue enjoying uninterrupted access to all features, please renew your subscription before the expiration date.

Subscription Details:
- Plan: {plan}
- End Date: {endDate}
- Renewal Amount: ${'{amount}'}

Click here to renew now: {renewalLink}

Thank you for being a valued subscriber!

Best regards,
UM Note Taker Team`
  },
  subscription_canceled: {
    subject: 'Subscription Cancellation Notice',
    body: `Dear {name},

We're sorry to see you go. Your UM Note Taker subscription has been canceled and will end on {endDate}.

If you'd like to reactivate your subscription, you can do so by clicking here: {reactivateLink}

We'd love to hear your feedback on how we can improve our service.

Thank you for being a subscriber.

Best regards,
UM Note Taker Team`
  }
};

class EmailService {
  private static instance: EmailService;
  private templates: EmailTemplates = DEFAULT_TEMPLATES;
  private initialized = false;

  private constructor() {
    this.initialize();
  }

  static getInstance(): EmailService {
    if (!EmailService.instance) {
      EmailService.instance = new EmailService();
    }
    return EmailService.instance;
  }

  private async initialize() {
    if (this.initialized) return;
    
    try {
      const savedTemplates = await LocalStorageService.getData('emailTemplates');
      if (savedTemplates?.length) {
        this.templates = savedTemplates[0];
      }
      this.initialized = true;
    } catch (error) {
      console.error('Error loading email templates:', error);
      this.templates = DEFAULT_TEMPLATES;
      this.initialized = true;
    }
  }

  private async saveTemplates() {
    try {
      await LocalStorageService.setData('emailTemplates', [this.templates]);
    } catch (error) {
      console.error('Error saving email templates:', error);
    }
  }

  getTemplates(): EmailTemplates {
    return { ...this.templates };
  }

  async updateTemplate(type: string, template: EmailTemplate): Promise<void> {
    if (!this.templates[type]) {
      throw new Error(`Invalid template type: ${type}`);
    }

    this.templates[type] = template;
    await this.saveTemplates();
  }

  async sendAlert(
    type: 'payment_failed' | 'subscription_ending' | 'subscription_canceled',
    data: Record<string, string>
  ): Promise<void> {
    const template = this.templates[type];
    if (!template) {
      throw new Error(`Template not found for type: ${type}`);
    }

    let subject = template.subject;
    let body = template.body;

    // Replace variables in template
    Object.entries(data).forEach(([key, value]) => {
      const regex = new RegExp(`{${key}}`, 'g');
      subject = subject.replace(regex, value);
      body = body.replace(regex, value);
    });

    // In a real application, this would send the email via an email service
    console.log('Sending email:', {
      type,
      subject,
      body,
      to: data.email
    });
  }

  resetTemplates(): void {
    this.templates = DEFAULT_TEMPLATES;
    this.saveTemplates();
  }
}

export const emailService = EmailService.getInstance();