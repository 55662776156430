import React from 'react';
import { Loader2 } from 'lucide-react';

interface OCRProgressProps {
  processing: boolean;
  progress?: number;
}

const OCRProgress: React.FC<OCRProgressProps> = ({ processing, progress }) => {
  if (!processing) return null;

  return (
    <div className="flex flex-col items-center justify-center py-4">
      <div className="flex items-center gap-3 text-white/80 mb-2">
        <Loader2 className="h-6 w-6 animate-spin" />
        <span>Processing documents...</span>
      </div>
      {progress !== undefined && (
        <div className="w-full max-w-md bg-white/10 rounded-full h-2.5">
          <div 
            className="bg-indigo-600 h-2.5 rounded-full transition-all duration-300"
            style={{ width: `${progress}%` }}
          />
        </div>
      )}
    </div>
  );
};

export default OCRProgress;