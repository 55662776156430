import { OpenAIService } from './openai/service';
import { LocalStorageService } from './LocalStorageService';
import { CptCode } from '../types';

export class CptCodeService {
  static async validateAndGetDescription(code: string): Promise<string | null> {
    try {
      // Skip 94060 unless explicitly required
      if (code.toUpperCase() === '94060') {
        return null;
      }

      const response = await OpenAIService.analyzeClinicalNotes(code, 'get_description', []);
      return response.description || null;
    } catch (error) {
      console.error('Error getting CPT code description:', error);
      return null;
    }
  }

  static async findMatchingGuidelines(code: string, lob: string): Promise<string[]> {
    try {
      const cptCodes = await LocalStorageService.getData('cptCodes') as CptCode[];
      return cptCodes
        .filter(cpt => 
          cpt.code.toUpperCase() === code.toUpperCase() && 
          cpt.lob.toLowerCase() === lob.toLowerCase()
        )
        .map(cpt => cpt.guideline);
    } catch (error) {
      console.error('Error finding matching guidelines:', error);
      return [];
    }
  }

  static extractCodesFromText(text: string): string[] {
    if (!text?.trim()) return [];
    
    // Case insensitive regex for both numeric and alphanumeric codes
    const cptCodeRegex = /\b([A-Za-z]\d{4}|\d{5})\b/gi;
    const matches = text.match(cptCodeRegex) || [];
    
    // Filter out 94060 unless it's explicitly mentioned in a medical context
    const codes = Array.from(new Set(matches))
      .filter(code => {
        const upperCode = code.toUpperCase();
        if (upperCode === '94060') {
          // Only include 94060 if it appears in a clear medical context
          const context = text.toLowerCase();
          return context.includes('pulmonary function') || 
                 context.includes('spirometry') || 
                 context.includes('bronchodilation');
        }
        return true;
      })
      .map(code => code.toUpperCase());

    return codes;
  }

  static async addNewCode(data: {
    code: string;
    description: string;
    lob?: string;
    guideline?: string;
  }): Promise<void> {
    try {
      const procedures = await LocalStorageService.getData('procedures');
      const newProcedures = [...procedures, {
        code: data.code.toUpperCase(),
        description: data.description,
        procedureMd: '0'
      }];
      await LocalStorageService.setData('procedures', newProcedures);

      if (data.lob && data.guideline) {
        const cptCodes = await LocalStorageService.getData('cptCodes');
        const newCptCodes = [...cptCodes, {
          code: data.code.toUpperCase(),
          lob: data.lob,
          guideline: data.guideline
        }];
        await LocalStorageService.setData('cptCodes', newCptCodes);
      }
    } catch (error) {
      console.error('Error adding new CPT code:', error);
      throw error;
    }
  }

  static async extractTextFromImage(file: File): Promise<string> {
    // Implementation for OCR processing would go here
    // For now, return empty string as OCR is handled elsewhere
    return '';
  }
}