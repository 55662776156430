export class RateLimiter {
  private lastRequestTime: number = 0;
  private tokensUsedInLastMinute: number = 0;
  private tokenHistory: Array<{ timestamp: number; tokens: number }> = [];
  private readonly MAX_TOKENS_PER_MIN = 180000;
  private readonly MIN_REQUEST_INTERVAL = 1000;

  private cleanHistory(): void {
    const now = Date.now();
    const oneMinuteAgo = now - 60000;
    this.tokenHistory = this.tokenHistory.filter(entry => entry.timestamp >= oneMinuteAgo);
    this.tokensUsedInLastMinute = this.tokenHistory.reduce((sum, entry) => sum + entry.tokens, 0);
  }

  async waitForAvailability(): Promise<void> {
    this.cleanHistory();
    
    const now = Date.now();
    const timeSinceLastRequest = now - this.lastRequestTime;
    
    if (timeSinceLastRequest < this.MIN_REQUEST_INTERVAL) {
      await new Promise(resolve => 
        setTimeout(resolve, this.MIN_REQUEST_INTERVAL - timeSinceLastRequest)
      );
    }

    while (this.tokensUsedInLastMinute >= this.MAX_TOKENS_PER_MIN) {
      await new Promise(resolve => setTimeout(resolve, 1000));
      this.cleanHistory();
    }
    
    this.lastRequestTime = Date.now();
  }

  recordTokenUsage(tokens: number): void {
    this.tokenHistory.push({
      timestamp: Date.now(),
      tokens
    });
    this.tokensUsedInLastMinute += tokens;
  }
}

export const rateLimiter = new RateLimiter();