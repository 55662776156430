import React, { useState } from 'react';
import { Search, Download } from 'lucide-react';

interface Payment {
  id: string;
  subscriber: string;
  amount: number;
  status: 'success' | 'failed' | 'pending';
  date: string;
}

export const PaymentHistory = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [payments] = useState<Payment[]>([
    {
      id: '1',
      subscriber: 'John Doe',
      amount: 99,
      status: 'success',
      date: '2024-03-15'
    },
    {
      id: '2',
      subscriber: 'Jane Smith',
      amount: 89,
      status: 'failed',
      date: '2024-03-14'
    }
  ]);

  const filteredPayments = payments.filter(payment =>
    payment.subscriber.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const getStatusColor = (status: Payment['status']) => {
    switch (status) {
      case 'success':
        return 'bg-green-100 text-green-800';
      case 'failed':
        return 'bg-red-100 text-red-800';
      case 'pending':
        return 'bg-yellow-100 text-yellow-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  const exportPayments = () => {
    // TODO: Implement CSV export
    console.log('Exporting payments...');
  };

  return (
    <div className="bg-white rounded-lg shadow-sm mt-6">
      <div className="p-6 border-b border-gray-200">
        <div className="flex items-center justify-between mb-4">
          <h2 className="text-lg font-semibold text-gray-900">Payment History</h2>
          <button
            onClick={exportPayments}
            className="flex items-center px-3 py-2 text-sm text-indigo-600 hover:text-indigo-800"
          >
            <Download className="h-4 w-4 mr-1" />
            Export
          </button>
        </div>

        <div className="relative">
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 h-5 w-5" />
          <input
            type="text"
            placeholder="Search payments..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="pl-10 pr-4 py-2 w-full border rounded-lg focus:ring-2 focus:ring-indigo-500"
          />
        </div>
      </div>

      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Subscriber
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Amount
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Status
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Date
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {filteredPayments.map((payment) => (
              <tr key={payment.id}>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm font-medium text-gray-900">
                    {payment.subscriber}
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-gray-900">
                    ${payment.amount}
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${getStatusColor(payment.status)}`}>
                    {payment.status}
                  </span>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {payment.date}
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {filteredPayments.length === 0 && (
          <div className="text-center py-4 text-gray-500">
            No payments found
          </div>
        )}
      </div>
    </div>
  );
};