interface DataOperation {
  type: 'read' | 'write';
  collection: string;
  timestamp: number;
  duration: number;
  success: boolean;
  error?: string;
}

export class DataAnalyzer {
  private static operations: DataOperation[] = [];

  static recordOperation(operation: Omit<DataOperation, 'timestamp'>) {
    this.operations.push({
      ...operation,
      timestamp: Date.now()
    });
  }

  static getOperationStats(): string {
    const stats = this.operations.reduce((acc, op) => {
      if (!acc[op.collection]) {
        acc[op.collection] = {
          reads: 0,
          writes: 0,
          failures: 0,
          avgDuration: 0
        };
      }

      const collection = acc[op.collection];
      if (op.type === 'read') collection.reads++;
      if (op.type === 'write') collection.writes++;
      if (!op.success) collection.failures++;
      collection.avgDuration = (collection.avgDuration + op.duration) / 2;

      return acc;
    }, {} as Record<string, { reads: number; writes: number; failures: number; avgDuration: number }>);

    let report = 'Data Operation Statistics\n';
    report += '------------------------\n';

    Object.entries(stats).forEach(([collection, stat]) => {
      report += `\n${collection}:\n`;
      report += `  Reads: ${stat.reads}\n`;
      report += `  Writes: ${stat.writes}\n`;
      report += `  Failures: ${stat.failures}\n`;
      report += `  Avg Duration: ${stat.avgDuration.toFixed(2)}ms\n`;
    });

    return report;
  }

  static diagnoseDataIssues(): string[] {
    const issues: string[] = [];

    const recentOps = this.operations.filter(
      op => Date.now() - op.timestamp < 5 * 60 * 1000 // Last 5 minutes
    );

    const failures = recentOps.filter(op => !op.success);
    if (failures.length > 0) {
      issues.push(`${failures.length} failed operations in the last 5 minutes`);
    }

    const slowOps = recentOps.filter(op => op.duration > 1000);
    if (slowOps.length > 0) {
      issues.push(`${slowOps.length} slow operations (>1s) in the last 5 minutes`);
    }

    return issues;
  }
}