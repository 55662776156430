import { LocalStorageService } from './LocalStorageService';
import { emailService } from './EmailService';
import { hashPassword, verifyPassword } from '../utils/crypto';

export interface Subscriber {
  id: string;
  name: string;
  username: string;
  password: string;
  email: string;
  plan: 'monthly' | 'annual' | 'free';
  status: 'active' | 'inactive';
  subscriptionEnd: string;
  lastPayment?: string;
  nextPayment?: string;
  freeAccess?: boolean;
}

class SubscriberService {
  private static instance: SubscriberService;
  private subscribers: Subscriber[] = [];
  private initialized = false;

  private constructor() {
    this.initialize();
  }

  static getInstance(): SubscriberService {
    if (!SubscriberService.instance) {
      SubscriberService.instance = new SubscriberService();
    }
    return SubscriberService.instance;
  }

  private async initialize() {
    if (this.initialized) return;
    
    try {
      const data = await LocalStorageService.getData('subscribers');
      this.subscribers = data as Subscriber[];
      this.initialized = true;
    } catch (error) {
      console.error('Error loading subscribers:', error);
      this.subscribers = [];
      this.initialized = true;
    }
  }

  private async saveSubscribers() {
    try {
      await LocalStorageService.setData('subscribers', this.subscribers);
    } catch (error) {
      console.error('Error saving subscribers:', error);
    }
  }

  async addSubscriber(subscriber: Omit<Subscriber, 'id' | 'status' | 'subscriptionEnd'>): Promise<Subscriber> {
    await this.initialize();

    const hashedPassword = await hashPassword(subscriber.password);
    
    const newSubscriber: Subscriber = {
      id: crypto.randomUUID(),
      ...subscriber,
      password: hashedPassword,
      status: 'active',
      subscriptionEnd: subscriber.plan === 'free' 
        ? new Date(Date.now() + 365 * 24 * 60 * 60 * 1000).toISOString() // 1 year for free accounts
        : new Date(Date.now() + 30 * 24 * 60 * 60 * 1000).toISOString(), // 30 days for paid accounts
      nextPayment: subscriber.plan === 'free' 
        ? undefined 
        : new Date(Date.now() + 30 * 24 * 60 * 60 * 1000).toISOString(),
      freeAccess: subscriber.plan === 'free'
    };

    this.subscribers.push(newSubscriber);
    await this.saveSubscribers();
    return newSubscriber;
  }

  async validateCredentials(username: string, password: string): Promise<Subscriber | null> {
    await this.initialize();
    
    const subscriber = this.subscribers.find(s => s.username === username);
    if (!subscriber) return null;

    const isValid = await verifyPassword(password, subscriber.password);
    return isValid ? subscriber : null;
  }

  async updateSubscriber(id: string, updates: Partial<Subscriber>): Promise<Subscriber | null> {
    await this.initialize();

    const index = this.subscribers.findIndex(s => s.id === id);
    if (index === -1) return null;

    if (updates.password) {
      updates.password = await hashPassword(updates.password);
    }

    this.subscribers[index] = { ...this.subscribers[index], ...updates };
    await this.saveSubscribers();
    return this.subscribers[index];
  }

  async deleteSubscriber(id: string): Promise<boolean> {
    await this.initialize();

    const index = this.subscribers.findIndex(s => s.id === id);
    if (index === -1) return false;

    this.subscribers.splice(index, 1);
    await this.saveSubscribers();
    return true;
  }

  async checkSubscriptionStatus() {
    await this.initialize();

    const now = new Date();
    const threeDaysFromNow = new Date(now.getTime() + 3 * 24 * 60 * 60 * 1000);

    for (const subscriber of this.subscribers) {
      // Skip free access accounts
      if (subscriber.freeAccess) continue;

      const subscriptionEnd = new Date(subscriber.subscriptionEnd);
      const nextPayment = subscriber.nextPayment ? new Date(subscriber.nextPayment) : null;

      // Check for ending subscriptions
      if (subscriptionEnd <= threeDaysFromNow && subscriber.status === 'active') {
        await emailService.sendSubscriptionEndingNotification(
          subscriber.email,
          subscriber.name,
          subscriber.plan,
          subscriber.subscriptionEnd,
          subscriber.plan === 'monthly' ? '99' : '89',
          `https://example.com/renew/${subscriber.id}`
        );
      }

      // Check for failed payments
      if (nextPayment && nextPayment < now && subscriber.status === 'active') {
        await emailService.sendPaymentFailedNotification(
          subscriber.email,
          subscriber.name,
          subscriber.plan === 'monthly' ? '99' : '89',
          nextPayment.toISOString(),
          `https://example.com/payment/${subscriber.id}`
        );

        // Update status to inactive after grace period
        const gracePeriod = new Date(nextPayment.getTime() + 7 * 24 * 60 * 60 * 1000);
        if (now > gracePeriod) {
          await this.updateSubscriber(subscriber.id, { status: 'inactive' });
          await emailService.sendSubscriptionCanceledNotification(
            subscriber.email,
            subscriber.name,
            subscriptionEnd.toISOString(),
            `https://example.com/reactivate/${subscriber.id}`
          );
        }
      }
    }
  }

  getSubscribers(): Subscriber[] {
    return [...this.subscribers];
  }

  getActiveSubscribers(): Subscriber[] {
    return this.subscribers.filter(s => s.status === 'active');
  }

  getCanceledSubscribers(): Subscriber[] {
    return this.subscribers.filter(s => s.status === 'inactive');
  }

  getAtRiskSubscribers(): Subscriber[] {
    const now = new Date();
    const threeDaysFromNow = new Date(now.getTime() + 3 * 24 * 60 * 60 * 1000);
    
    return this.subscribers.filter(s => {
      if (s.freeAccess) return false;
      const subscriptionEnd = new Date(s.subscriptionEnd);
      return s.status === 'active' && subscriptionEnd <= threeDaysFromNow;
    });
  }

  getFreeAccessSubscribers(): Subscriber[] {
    return this.subscribers.filter(s => s.freeAccess);
  }
}

export const subscriberService = SubscriberService.getInstance();