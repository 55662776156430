import React from 'react';
import { AlertCircle } from 'lucide-react';

interface OCRErrorProps {
  error: string | null;
}

const OCRError: React.FC<OCRErrorProps> = ({ error }) => {
  if (!error) return null;

  return (
    <div className="p-4 bg-red-500/20 border border-red-500/20 rounded-lg">
      <div className="flex items-center gap-2">
        <AlertCircle className="h-5 w-5 text-red-300" />
        <p className="text-red-300 text-sm">{error}</p>
      </div>
    </div>
  );
};

export default OCRError;