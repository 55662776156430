import { useState, useCallback } from 'react';
import { OCRService } from '../services/ocr/ocrService';

export const useOCRProcessing = () => {
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const processFiles = useCallback(async (files: File[]): Promise<string | null> => {
    if (!files.length) return null;

    setProcessing(true);
    setError(null);

    try {
      const text = await OCRService.processMultipleFiles(files);
      return text;
    } catch (err) {
      console.error('OCR processing error:', err);
      setError(err instanceof Error ? err.message : 'Failed to process documents');
      return null;
    } finally {
      setProcessing(false);
    }
  }, []);

  return {
    processing,
    error,
    processFiles
  };
};