import React, { useState, useCallback, useEffect } from 'react';
import { useData } from '../../context/DataContext';
import { AlertCircle } from 'lucide-react';
import { CptCodeService } from '../../services/CptCodeService';
import DictationButton from '../DictationButton';
import ImageUpload from '../ImageUpload';
import UnknownCptCodeModal from '../UnknownCptCodeModal';

interface CptCodeInputProps {
  selectedCptCodes: string[];
  onCodesChange: (codes: string[]) => void;
  selectedLOB?: string;
}

const CptCodeInput: React.FC<CptCodeInputProps> = ({
  selectedCptCodes,
  onCodesChange,
  selectedLOB
}) => {
  const [inputValue, setInputValue] = useState('');
  const [error, setError] = useState<string | null>(null);
  const { cptCodes, procedures, updateData } = useData();
  const [unknownCode, setUnknownCode] = useState<{ code: string; description: string } | null>(null);
  const [isDragging, setIsDragging] = useState(false);

  const formatInput = (value: string) => {
    // Remove all spaces and convert to uppercase
    const cleaned = value.replace(/\s/g, '').toUpperCase();
    // Add space after every 5 characters
    return cleaned.replace(/(.{5})/g, '$1 ').trim();
  };

  const checkUnknownCodes = useCallback(async (codes: string[]) => {
    if (!codes?.length) return;

    const existingCodes = new Set([
      ...cptCodes.map(c => c.code.toUpperCase()),
      ...procedures.map(p => p.code.toUpperCase())
    ]);

    for (const code of codes) {
      const upperCode = code.toUpperCase();
      if (!existingCodes.has(upperCode)) {
        try {
          const description = await CptCodeService.validateAndGetDescription(upperCode);
          if (description) {
            setUnknownCode({ code: upperCode, description });
            return;
          }
        } catch (error) {
          console.error('Error validating CPT code:', error);
          setError('Failed to validate CPT code. Please try again.');
        }
      }
    }
  }, [cptCodes, procedures]);

  const handleInputChange = useCallback(async (value: string) => {
    const formattedValue = formatInput(value);
    setInputValue(formattedValue);
    setError(null);

    const codes = CptCodeService.extractCodesFromText(formattedValue);
    
    if (JSON.stringify(codes) !== JSON.stringify(selectedCptCodes)) {
      onCodesChange(codes);
      await checkUnknownCodes(codes);
    }
  }, [selectedCptCodes, onCodesChange, checkUnknownCodes]);

  const handlePaste = async (e: React.ClipboardEvent) => {
    const text = e.clipboardData.getData('text');
    await handleInputChange(text);
  };

  const handleDrop = async (e: React.DragEvent) => {
    e.preventDefault();
    setIsDragging(false);

    const files = Array.from(e.dataTransfer.files);
    const imageFiles = files.filter(file => file.type.startsWith('image/'));
    
    if (imageFiles.length > 0) {
      try {
        const texts = await Promise.all(imageFiles.map(file => 
          CptCodeService.extractTextFromImage(file)
        ));
        await handleInputChange(texts.join('\n'));
      } catch (error) {
        console.error('Error processing dropped files:', error);
        setError('Failed to process dropped files');
      }
    } else {
      const text = e.dataTransfer.getData('text');
      await handleInputChange(text);
    }
  };

  const handleDictationResult = async (text: string) => {
    await handleInputChange(inputValue + ' ' + text);
  };

  const handleImageUpload = async (text: string) => {
    await handleInputChange(inputValue + ' ' + text);
  };

  const handleAddUnknownCode = async () => {
    if (!unknownCode) return;

    try {
      await CptCodeService.addNewCode({
        code: unknownCode.code,
        description: unknownCode.description,
        lob: selectedLOB,
        guideline: ''
      });
      setUnknownCode(null);
    } catch (error) {
      console.error('Error adding unknown code:', error);
      setError('Failed to add CPT code. Please try again.');
    }
  };

  // Reset input when selectedCptCodes is cleared
  useEffect(() => {
    if (selectedCptCodes.length === 0 && inputValue) {
      setInputValue('');
    }
  }, [selectedCptCodes]);

  return (
    <div>
      <div 
        className={`border-2 border-dashed rounded-md p-4 transition-colors ${
          isDragging ? 'border-indigo-400 bg-indigo-50/10' : 'border-white/20'
        }`}
        onDragOver={(e) => {
          e.preventDefault();
          setIsDragging(true);
        }}
        onDragLeave={(e) => {
          e.preventDefault();
          setIsDragging(false);
        }}
        onDrop={handleDrop}
      >
        <label className="block text-sm font-medium text-white mb-2">
          CPT Code
        </label>
        <textarea
          value={inputValue}
          onChange={(e) => handleInputChange(e.target.value)}
          onPaste={handlePaste}
          rows={3}
          className="w-full px-3 py-2 bg-white/10 border border-white/20 rounded-lg text-white placeholder-white/50 focus:ring-2 focus:ring-indigo-500"
          placeholder="Enter CPT codes, paste text, or drag & drop files..."
          style={{ minHeight: '100px' }}
        />
        
        {selectedCptCodes.length > 0 && (
          <div className="mt-2 flex flex-wrap gap-2">
            {selectedCptCodes.map((code) => (
              <span
                key={code}
                className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-indigo-100 text-indigo-800"
              >
                {code}
              </span>
            ))}
          </div>
        )}

        <div className="mt-2 flex justify-end space-x-2">
          <DictationButton onTextReceived={handleDictationResult} />
          <ImageUpload onTextExtracted={handleImageUpload} />
        </div>

        {error && (
          <div className="mt-2 p-3 bg-red-500/20 rounded-md flex items-center">
            <AlertCircle className="h-5 w-5 text-red-400 mr-2" />
            <span className="text-sm text-red-300">{error}</span>
          </div>
        )}
      </div>

      {unknownCode && (
        <UnknownCptCodeModal
          cptCode={unknownCode.code}
          suggestedDescription={unknownCode.description}
          onClose={() => setUnknownCode(null)}
          onSave={handleAddUnknownCode}
        />
      )}
    </div>
  );
};

export default CptCodeInput;