import React from 'react';
import { DollarSign, Users, TrendingDown, AlertCircle } from 'lucide-react';

interface BillingStatsProps {
  monthlyRevenue: number;
  activeSubscriptions: number;
  canceledSubscriptions: number;
  atRiskSubscriptions: number;
}

export const BillingStats: React.FC<BillingStatsProps> = ({
  monthlyRevenue,
  activeSubscriptions,
  canceledSubscriptions,
  atRiskSubscriptions
}) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
      <div className="bg-white rounded-lg shadow-sm p-6">
        <div className="flex items-center">
          <div className="p-2 bg-green-100 rounded-lg">
            <DollarSign className="h-6 w-6 text-green-600" />
          </div>
          <div className="ml-4">
            <p className="text-sm font-medium text-gray-500">Monthly Revenue</p>
            <h3 className="text-xl font-bold text-gray-900">
              ${monthlyRevenue.toLocaleString()}
            </h3>
          </div>
        </div>
      </div>

      <div className="bg-white rounded-lg shadow-sm p-6">
        <div className="flex items-center">
          <div className="p-2 bg-blue-100 rounded-lg">
            <Users className="h-6 w-6 text-blue-600" />
          </div>
          <div className="ml-4">
            <p className="text-sm font-medium text-gray-500">Active Subscriptions</p>
            <h3 className="text-xl font-bold text-gray-900">
              {activeSubscriptions}
            </h3>
          </div>
        </div>
      </div>

      <div className="bg-white rounded-lg shadow-sm p-6">
        <div className="flex items-center">
          <div className="p-2 bg-red-100 rounded-lg">
            <TrendingDown className="h-6 w-6 text-red-600" />
          </div>
          <div className="ml-4">
            <p className="text-sm font-medium text-gray-500">Canceled</p>
            <h3 className="text-xl font-bold text-gray-900">
              {canceledSubscriptions}
            </h3>
          </div>
        </div>
      </div>

      <div className="bg-white rounded-lg shadow-sm p-6">
        <div className="flex items-center">
          <div className="p-2 bg-yellow-100 rounded-lg">
            <AlertCircle className="h-6 w-6 text-yellow-600" />
          </div>
          <div className="ml-4">
            <p className="text-sm font-medium text-gray-500">At Risk</p>
            <h3 className="text-xl font-bold text-gray-900">
              {atRiskSubscriptions}
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
};