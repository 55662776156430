import React, { useState } from 'react';
import ReviewForm from '../components/ReviewForm';
import ReviewOutput from '../components/ReviewOutput';
import ReviewCounter from '../components/ReviewCounter';
import ReviewTimer from '../components/ReviewTimer';
import GuidelineProcessor from '../components/admin/GuidelineProcessor';
import AIChat from '../components/AIChat';
import { Review } from '../types';
import { useAuth } from '../context/AuthContext';

const Dashboard = () => {
  const { user } = useAuth();
  const [review, setReview] = useState<Review>({
    reasonForRequest: '',
    dateOfService: new Date().toISOString(),
    medicalHistory: '',
    criteriaApplied: [],
    recommendation: '',
    signature: user?.isAdmin ? 'C. Starks, LVN' : `${user?.name || ''}, ${user?.credentials || ''}`,
    additionalNote: 'N/A, current attached clinical notes reviewed.'
  });
  const [selectedCptCodes, setSelectedCptCodes] = useState<string[]>([]);
  const [error, setError] = useState<string | null>(null);

  const handleReviewUpdate = (newReview: Review, cptCodes: string[]) => {
    setReview({
      ...newReview,
      signature: user?.isAdmin ? 'C. Starks, LVN' : `${user?.name || ''}, ${user?.credentials || ''}`
    });
    setSelectedCptCodes(cptCodes);
  };

  if (error) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-indigo-900 via-blue-900 to-blue-800 animate-[gradient_4s_ease_infinite] py-6">
        <div className="max-w-7xl mx-auto px-4">
          <div className="relative">
            <div className="absolute inset-0 bg-red-500/20 rounded-2xl blur-xl"></div>
            <div className="relative backdrop-blur-md bg-black/40 border border-white/20 p-6 rounded-2xl">
              <p className="text-red-300">{error}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-indigo-900 via-blue-900 to-blue-800 animate-[gradient_4s_ease_infinite] py-6">
      <div className="max-w-7xl mx-auto px-4">
        <div className="flex justify-between items-center mb-6">
          <div className="relative group">
            <div className="absolute inset-0 bg-gradient-to-r from-blue-500/30 to-indigo-500/30 rounded-xl blur-lg"></div>
            <div className="relative backdrop-blur-md bg-black/40 border border-white/20 p-4 rounded-xl">
              <ReviewCounter />
            </div>
          </div>
          <div className="relative group">
            <div className="absolute inset-0 bg-gradient-to-r from-blue-500/30 to-indigo-500/30 rounded-xl blur-lg"></div>
            <div className="relative backdrop-blur-md bg-black/40 border border-white/20 p-4 rounded-xl">
              <ReviewTimer />
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          <div className="lg:sticky lg:top-6 lg:self-start space-y-6">
            <div className="relative group">
              <div className="absolute inset-0 bg-gradient-to-r from-blue-500/20 to-indigo-500/20 rounded-2xl blur-xl"></div>
              <div className="relative backdrop-blur-md bg-black/30 border border-white/20 p-6 rounded-2xl">
                <h2 className="text-2xl font-bold text-white mb-4">Clinical Review Input</h2>
                <ReviewForm onReviewGenerate={handleReviewUpdate} />
              </div>
            </div>
            
            <div className="relative group">
              <div className="absolute inset-0 bg-gradient-to-r from-blue-500/30 to-indigo-500/30 rounded-2xl blur-xl"></div>
              <div className="relative backdrop-blur-md bg-black/40 border border-white/20 p-6 rounded-2xl">
                <h2 className="text-2xl font-bold text-white mb-4">AI Processor</h2>
                <GuidelineProcessor />
              </div>
            </div>
          </div>

          <div className="space-y-6">
            <div className="relative group">
              <div className="absolute inset-0 bg-gradient-to-r from-blue-500/30 to-indigo-500/30 rounded-2xl blur-xl"></div>
              <div className="relative backdrop-blur-md bg-black/40 border border-white/20 p-6 rounded-2xl">
                <h2 className="text-2xl font-bold text-white mb-4">Generated Review</h2>
                {review.medicalHistory && <ReviewOutput review={review} cptCodes={selectedCptCodes} />}
              </div>
            </div>
          </div>
        </div>

        {review.medicalHistory && (
          <AIChat
            clinicalNotes={review.medicalHistory}
            guidelines={review.criteriaApplied}
            analysis={{
              summary: review.medicalHistory,
              review: review.recommendation
            }}
          />
        )}
      </div>
    </div>
  );
};

export default Dashboard;