import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { AISettings } from '../services/openai/types';

interface AISettingsState {
  settings: AISettings;
  initialized: boolean;
  updateSettings: (settings: Partial<AISettings>) => void;
}

// Remove default settings - we'll initialize them only if needed
export const useAISettings = create<AISettingsState>()(
  persist(
    (set) => ({
      settings: {
        medicalHistoryPrompt: '',
        recommendationPrompt: '',
        clinicalSummaryPrompt: '',
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString()
      },
      initialized: false,
      updateSettings: (newSettings) => set((state) => ({
        settings: {
          ...state.settings,
          ...newSettings,
          updatedAt: new Date().toISOString()
        },
        initialized: true
      }))
    }),
    {
      name: 'ai-settings',
      version: 1,
      storage: localStorage
    }
  )
);