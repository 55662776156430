import { createWorker, createScheduler } from 'tesseract.js';
import { FileValidator } from './fileValidator';
import { TextProcessor } from './textProcessor';
import { OCRError } from './errors';

export class OCRService {
  private static scheduler: Tesseract.Scheduler | null = null;
  private static readonly MAX_WORKERS = 4;
  private static readonly MAX_PAGES = 40;
  private static readonly PAGE_SIZE_ESTIMATE = 100000; // ~100KB per page

  private static async initializeScheduler() {
    if (this.scheduler) return this.scheduler;

    try {
      this.scheduler = createScheduler();
      const workers = [];

      for (let i = 0; i < this.MAX_WORKERS; i++) {
        const worker = await createWorker('eng', 1, {
          logger: m => console.log(`Worker ${i + 1}:`, m),
          errorHandler: err => console.error(`Worker ${i + 1} error:`, err)
        });
        workers.push(worker);
      }

      await Promise.all(workers.map(worker => this.scheduler?.addWorker(worker)));
      return this.scheduler;
    } catch (error) {
      console.error('Scheduler initialization error:', error);
      throw new OCRError('Failed to initialize OCR system');
    }
  }

  static async processFile(file: File): Promise<string> {
    const validation = FileValidator.validateFile(file);
    if (!validation.valid) {
      throw new OCRError(validation.error || 'Invalid file');
    }

    const estimatedPages = file.size / this.PAGE_SIZE_ESTIMATE;
    if (estimatedPages > this.MAX_PAGES) {
      throw new OCRError(`File too large. Maximum ${this.MAX_PAGES} pages allowed.`);
    }

    try {
      const scheduler = await this.initializeScheduler();
      const imageUrl = URL.createObjectURL(file);
      
      const result = await scheduler.addJob('recognize', imageUrl);
      URL.revokeObjectURL(imageUrl);
      
      if (!result?.data?.text) {
        throw new OCRError('No text could be extracted from file');
      }

      const processedText = TextProcessor.processText(result.data.text);
      if (!processedText) {
        throw new OCRError('No valid text found in file');
      }

      return processedText;
    } catch (err) {
      console.error('OCR processing error:', err);
      if (err instanceof OCRError) throw err;
      throw new OCRError('Failed to process file. Please try again.');
    }
  }

  static async processMultipleFiles(files: File[]): Promise<string> {
    const validation = FileValidator.validateFiles(files);
    if (!validation.valid) {
      throw new OCRError(validation.error || 'Invalid files');
    }

    const totalEstimatedPages = files.reduce((acc, file) => 
      acc + (file.size / this.PAGE_SIZE_ESTIMATE), 0
    );

    if (totalEstimatedPages > this.MAX_PAGES) {
      throw new OCRError(`Total pages exceed maximum limit of ${this.MAX_PAGES} pages.`);
    }

    try {
      const scheduler = await this.initializeScheduler();
      
      const results = await Promise.all(
        files.map(async file => {
          const imageUrl = URL.createObjectURL(file);
          try {
            const result = await scheduler.addJob('recognize', imageUrl);
            if (!result?.data?.text) {
              console.warn(`No text extracted from file: ${file.name}`);
              return '';
            }
            return TextProcessor.processText(result.data.text);
          } finally {
            URL.revokeObjectURL(imageUrl);
          }
        })
      );

      const processedText = results.filter(Boolean).join('\n\n');
      if (!processedText) {
        throw new OCRError('No text could be extracted from any of the files');
      }

      return processedText;
    } catch (err) {
      console.error('Batch OCR processing error:', err);
      if (err instanceof OCRError) throw err;
      throw new OCRError('Failed to process files. Please try different files.');
    }
  }

  static async cleanup(): Promise<void> {
    if (this.scheduler) {
      try {
        await this.scheduler.terminate();
        this.scheduler = null;
      } catch (error) {
        console.warn('Cleanup warning:', error);
      }
    }
  }
}