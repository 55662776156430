import React from 'react';
import { CreditCard, Copy, Check, ExternalLink } from 'lucide-react';

const StripeSetupGuide = () => {
  const [copied, setCopied] = React.useState(false);

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <div className="bg-white rounded-lg shadow-sm p-6 mb-6">
      <div className="flex items-center mb-4">
        <CreditCard className="h-6 w-6 text-indigo-600 mr-2" />
        <h2 className="text-xl font-semibold">Stripe Setup Guide</h2>
      </div>

      <div className="space-y-6">
        <div>
          <h3 className="text-lg font-medium text-gray-900 mb-2">1. Create Stripe Account</h3>
          <p className="text-gray-600 mb-2">
            Sign up for a Stripe account at{' '}
            <a 
              href="https://dashboard.stripe.com/register" 
              target="_blank" 
              rel="noopener noreferrer"
              className="text-indigo-600 hover:text-indigo-800"
            >
              dashboard.stripe.com/register
              <ExternalLink className="inline-block h-4 w-4 ml-1" />
            </a>
          </p>
        </div>

        <div>
          <h3 className="text-lg font-medium text-gray-900 mb-2">2. Get API Keys</h3>
          <p className="text-gray-600 mb-2">
            Navigate to Developers → API keys in your Stripe Dashboard
          </p>
          <div className="bg-gray-50 p-4 rounded-md">
            <code className="text-sm">
              STRIPE_SECRET_KEY=sk_test_...
            </code>
            <button
              onClick={() => copyToClipboard('STRIPE_SECRET_KEY=sk_test_...')}
              className="ml-2 text-gray-400 hover:text-gray-600"
            >
              {copied ? (
                <Check className="h-4 w-4 text-green-500" />
              ) : (
                <Copy className="h-4 w-4" />
              )}
            </button>
          </div>
        </div>

        <div>
          <h3 className="text-lg font-medium text-gray-900 mb-2">3. Configure Products</h3>
          <p className="text-gray-600 mb-4">
            Create your subscription products in the Stripe Dashboard:
          </p>
          <ol className="list-decimal list-inside space-y-2 text-gray-600">
            <li>Go to Products → Add Product</li>
            <li>Create products for Monthly ($99) and Annual ($89/month) plans</li>
            <li>Set up recurring prices for each product</li>
            <li>Note the price IDs (e.g., price_H2...))</li>
          </ol>
        </div>

        <div>
          <h3 className="text-lg font-medium text-gray-900 mb-2">4. Update Environment Variables</h3>
          <p className="text-gray-600 mb-2">
            Add these variables to your .env file:
          </p>
          <div className="bg-gray-50 p-4 rounded-md space-y-2">
            <div className="flex items-center">
              <code className="text-sm">STRIPE_SECRET_KEY=sk_test_...</code>
              <button
                onClick={() => copyToClipboard('STRIPE_SECRET_KEY=sk_test_...')}
                className="ml-2 text-gray-400 hover:text-gray-600"
              >
                <Copy className="h-4 w-4" />
              </button>
            </div>
            <div className="flex items-center">
              <code className="text-sm">STRIPE_MONTHLY_PRICE_ID=price_H2...</code>
              <button
                onClick={() => copyToClipboard('STRIPE_MONTHLY_PRICE_ID=price_H2...')}
                className="ml-2 text-gray-400 hover:text-gray-600"
              >
                <Copy className="h-4 w-4" />
              </button>
            </div>
            <div className="flex items-center">
              <code className="text-sm">STRIPE_ANNUAL_PRICE_ID=price_H2...</code>
              <button
                onClick={() => copyToClipboard('STRIPE_ANNUAL_PRICE_ID=price_H2...')}
                className="ml-2 text-gray-400 hover:text-gray-600"
              >
                <Copy className="h-4 w-4" />
              </button>
            </div>
          </div>
        </div>

        <div>
          <h3 className="text-lg font-medium text-gray-900 mb-2">5. Test the Integration</h3>
          <p className="text-gray-600 mb-2">
            Use these test card numbers for testing:
          </p>
          <div className="bg-gray-50 p-4 rounded-md space-y-2">
            <p className="text-sm text-gray-600">
              Success: <code className="bg-gray-100 px-2 py-1 rounded">4242 4242 4242 4242</code>
            </p>
            <p className="text-sm text-gray-600">
              Decline: <code className="bg-gray-100 px-2 py-1 rounded">4000 0000 0000 0002</code>
            </p>
          </div>
        </div>

        <div className="bg-blue-50 border-l-4 border-blue-400 p-4 mt-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <ExternalLink className="h-5 w-5 text-blue-400" />
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-blue-800">
                Need more help?
              </h3>
              <div className="mt-2 text-sm text-blue-700">
                <p>
                  Check out the{' '}
                  <a
                    href="https://stripe.com/docs"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="font-medium underline"
                  >
                    Stripe documentation
                  </a>
                  {' '}for detailed setup instructions and best practices.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StripeSetupGuide;