export class NameRemovalService {
  private static readonly NAME_PATTERNS = [
    /(?:Patient Name|Name|Patient):\s*([A-Z][a-z]+(?:\s+[A-Z][a-z]+){1,2})/g,
    /(?:Mr\.|Mrs\.|Ms\.|Dr\.)\s+([A-Z][a-z]+(?:\s+[A-Z][a-z]+){1,2})/g,
    /([A-Z][a-z]+(?:\s+[A-Z][a-z]+){1,2})\s+(?:reports|states|denies)/g
  ];

  static removeNames(text: string): string {
    let sanitizedText = text;

    this.NAME_PATTERNS.forEach(pattern => {
      sanitizedText = sanitizedText.replace(pattern, (match, name) => {
        return match.replace(name, '[REDACTED]');
      });
    });

    return sanitizedText;
  }
}