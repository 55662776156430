import { openai } from './client';
import { openAIConfig } from './config';
import { OpenAIError } from './errors';
import { rateLimiter } from './rateLimiter';
import { truncateText, splitTextIntoChunks, countTokens } from './tokenizer';

export async function generateCompletion(
  text: string,
  systemPrompt: string,
  guidelines: string[] = []
): Promise<string> {
  if (!text?.trim()) {
    throw new OpenAIError('Text input is required', 'invalid_input');
  }

  try {
    await rateLimiter.waitForAvailability();

    // Split text into manageable chunks
    const chunks = splitTextIntoChunks(text);
    let responses: string[] = [];

    // Process each chunk with retries
    for (const chunk of chunks) {
      let attempts = 0;
      let success = false;

      while (attempts < openAIConfig.retryAttempts && !success) {
        try {
          const truncatedChunk = truncateText(chunk);
          const completion = await openai.chat.completions.create({
            model: openAIConfig.model,
            messages: [
              {
                role: 'system',
                content: systemPrompt
              },
              {
                role: 'user',
                content: `${truncatedChunk}\n\n${guidelines.length > 0 ? `Guidelines to consider:\n${guidelines.join('\n')}\n\n` : ''}`
              }
            ],
            temperature: openAIConfig.temperature,
            max_tokens: openAIConfig.maxTokens
          });

          const content = completion.choices[0]?.message?.content;
          if (!content) {
            throw new OpenAIError('No response content from OpenAI', 'empty_response');
          }

          responses.push(content.trim());
          success = true;
        } catch (error: any) {
          attempts++;
          if (attempts === openAIConfig.retryAttempts) {
            throw error;
          }
          await new Promise(resolve => setTimeout(resolve, openAIConfig.retryDelay * attempts));
        }
      }
    }

    // Combine responses intelligently
    return responses.join('\n\n');

  } catch (error) {
    console.error('OpenAI API error:', error);
    throw error;
  }
}