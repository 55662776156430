import React, { useState } from 'react';
import { Copy, Check } from 'lucide-react';
import { useAudioStore } from '../../store/audioStore';
import { SpeechButton } from '../speech/SpeechButton';
import { useSpeechSynthesis } from '../speech/useSpeechSynthesis';

interface ClinicalSummaryProps {
  summary: string;
  keyFindings?: string[];
  recommendations?: string[];
  isLoading: boolean;
  error?: string | null;
}

const ClinicalSummary: React.FC<ClinicalSummaryProps> = ({
  summary,
  keyFindings = [],
  recommendations = [],
  isLoading,
  error
}) => {
  const { isMuted } = useAudioStore();
  const { isPlaying, startSpeaking, stopSpeaking } = useSpeechSynthesis(summary);
  const [copied, setCopied] = useState(false);

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(summary);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      console.error('Failed to copy summary:', err);
    }
  };

  if (error) {
    return (
      <div className="mt-2 p-3 bg-red-500/20 text-red-300 rounded-md text-sm">
        {error}
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="mt-2 p-4 bg-white/5 rounded-md animate-pulse">
        <div className="h-4 bg-white/10 rounded w-3/4 mb-2"></div>
        <div className="h-4 bg-white/10 rounded w-1/2"></div>
      </div>
    );
  }

  if (!summary) return null;

  return (
    <div className="mt-2 p-4 bg-white/10 backdrop-blur-sm rounded-lg border border-white/20 shadow-sm">
      <div className="flex justify-between items-start mb-2">
        <h3 className="text-sm font-medium text-white">Clinical Summary</h3>
        <div className="flex items-center gap-2">
          <button
            onClick={handleCopy}
            className="p-2 text-white/60 hover:text-white rounded-full hover:bg-white/10 transition-colors"
            title="Copy summary"
          >
            {copied ? (
              <Check className="h-4 w-4 text-green-400" />
            ) : (
              <Copy className="h-4 w-4" />
            )}
          </button>
          <SpeechButton
            isPlaying={isPlaying}
            isMuted={isMuted}
            onStart={startSpeaking}
            onStop={stopSpeaking}
          />
        </div>
      </div>
      <p className="text-sm text-white/90 leading-relaxed whitespace-pre-wrap">
        {summary}
      </p>
    </div>
  );
};

export default ClinicalSummary;