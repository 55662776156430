import React, { useState, useCallback, useMemo, useRef, useEffect } from 'react';
import { ChevronDown, ChevronUp, Search } from 'lucide-react';
import { useData } from '../context/DataContext';
import { findMatchingGuideline } from '../utils/cptCodeUtils';
import { debounce } from '../lib/utils';
import Fuse from 'fuse.js';

interface GuidelineDisplayProps {
  cptCodes: string[];
  selectedLOB: string;
  selectedGuidelines: string[];
  onGuidelineSelect: (guidelines: string[]) => void;
}

const GuidelineDisplay: React.FC<GuidelineDisplayProps> = ({
  cptCodes,
  selectedLOB,
  selectedGuidelines,
  onGuidelineSelect
}) => {
  const [expandedGuidelines, setExpandedGuidelines] = useState<string[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isSearchFocused, setIsSearchFocused] = useState(false);
  const { cptCodes: allCptCodes, criteria } = useData();
  const searchRef = useRef<HTMLInputElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  // Get matching guidelines based on CPT codes and LOB
  const matchingGuidelines = useMemo(() => {
    if (!selectedLOB || !cptCodes?.length) return [];

    const guidelines = cptCodes
      .map(code => findMatchingGuideline(code, selectedLOB, allCptCodes))
      .filter(Boolean) as string[];

    return Array.from(new Set(guidelines));
  }, [cptCodes, selectedLOB, allCptCodes]);

  // Setup fuzzy search
  const fuse = useMemo(() => new Fuse(criteria.map(c => c.guideline), {
    threshold: 0.4,
    ignoreLocation: true,
    minMatchCharLength: 2
  }), [criteria]);

  // Get guidelines to display
  const availableGuidelines = useMemo(() => {
    if (!isSearchFocused && !searchTerm) {
      // Show only selected and matching guidelines when not searching
      return Array.from(new Set([...selectedGuidelines, ...matchingGuidelines]));
    }

    if (searchTerm) {
      // When searching, show search results
      const searchResults = fuse.search(searchTerm).map(result => result.item);
      return Array.from(new Set([...searchResults]));
    }

    return [];
  }, [searchTerm, fuse, selectedGuidelines, matchingGuidelines, isSearchFocused]);

  const debouncedSearch = useCallback(
    debounce((value: string) => setSearchTerm(value), 300),
    []
  );

  // Handle clicks outside the component
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
        setIsSearchFocused(false);
        setSearchTerm('');
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const toggleGuideline = (guideline: string) => {
    setExpandedGuidelines(prev => 
      prev.includes(guideline)
        ? prev.filter(g => g !== guideline)
        : [...prev, guideline]
    );
  };

  const handleGuidelineSelect = (guideline: string) => {
    const newGuidelines = selectedGuidelines.includes(guideline)
      ? selectedGuidelines.filter(g => g !== guideline)
      : [...selectedGuidelines, guideline];
    
    onGuidelineSelect(newGuidelines);
    setSearchTerm('');
    setIsSearchFocused(false);
  };

  return (
    <div className="space-y-4" ref={containerRef}>
      <div className="relative" ref={searchRef}>
        <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-white/60 h-5 w-5" />
        <input
          type="text"
          placeholder="Search guidelines..."
          onChange={(e) => debouncedSearch(e.target.value)}
          onFocus={() => setIsSearchFocused(true)}
          className="w-full pl-10 pr-4 py-2 bg-white/10 border border-white/20 rounded-lg text-white placeholder-white/50 focus:ring-2 focus:ring-indigo-500"
        />
      </div>

      <div className="space-y-2">
        {availableGuidelines.map((guideline, index) => {
          const criteriaItem = criteria.find(c => c.guideline === guideline);
          const isSelected = selectedGuidelines.includes(guideline);
          const isExpanded = expandedGuidelines.includes(guideline);
          const isFromCptCode = matchingGuidelines.includes(guideline);
          
          return (
            <div 
              key={`${guideline}-${index}`}
              className={`flowing-gradient rounded-xl p-3 transition-colors border border-white/20 hover:border-indigo-300 ${
                isFromCptCode ? 'border-l-4 border-l-indigo-500' : ''
              }`}
            >
              <div className="flex justify-between items-center">
                <div className="flex items-center space-x-3">
                  <button
                    type="button"
                    onClick={() => handleGuidelineSelect(guideline)}
                    className={`w-5 h-5 rounded-md border flex items-center justify-center transition-colors ${
                      isSelected 
                        ? 'bg-indigo-700 border-indigo-700 shadow-inner' 
                        : 'border-white/40 hover:border-indigo-600'
                    }`}
                  >
                    {isSelected && (
                      <span className="text-white text-sm">✓</span>
                    )}
                  </button>
                  <span className="text-sm font-medium text-white">{guideline}</span>
                </div>
                <div className="flex items-center space-x-2">
                  {isFromCptCode && (
                    <span className="text-xs text-indigo-300 bg-indigo-500/20 px-2 py-1 rounded-full">
                      CPT Match
                    </span>
                  )}
                  <button
                    type="button"
                    onClick={() => toggleGuideline(guideline)}
                    className="text-white/60 hover:text-white"
                  >
                    {isExpanded ? (
                      <ChevronUp className="h-4 w-4" />
                    ) : (
                      <ChevronDown className="h-4 w-4" />
                    )}
                  </button>
                </div>
              </div>
              {isExpanded && criteriaItem && (
                <div className="mt-2 pt-2 border-t border-white/20 text-sm text-white/80">
                  {criteriaItem.criteria}
                </div>
              )}
            </div>
          );
        })}

        {availableGuidelines.length === 0 && (
          <div className="text-white/60 text-sm">
            {searchTerm 
              ? 'No guidelines found matching your search.'
              : 'No guidelines selected. Search or select CPT codes to see matching guidelines.'}
          </div>
        )}
      </div>
    </div>
  );
};

export default GuidelineDisplay;