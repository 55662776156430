import React from 'react';
import { AlertCircle } from 'lucide-react';

interface ErrorFallbackProps {
  error: Error;
  resetErrorBoundary?: () => void;
}

const ErrorFallback: React.FC<ErrorFallbackProps> = ({ error, resetErrorBoundary }) => {
  return (
    <div className="min-h-screen flex items-center justify-center bg-red-50">
      <div className="bg-white rounded-lg shadow-lg p-8 max-w-md w-full mx-4">
        <div className="flex items-center mb-4">
          <AlertCircle className="h-8 w-8 text-red-500 mr-3" />
          <h2 className="text-xl font-semibold text-red-700">Something went wrong</h2>
        </div>
        <pre className="bg-red-50 p-4 rounded-lg text-sm text-red-600 mb-4 overflow-auto">
          {error.message}
        </pre>
        {resetErrorBoundary && (
          <button
            onClick={resetErrorBoundary}
            className="w-full px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700"
          >
            Try again
          </button>
        )}
      </div>
    </div>
  );
};

export default ErrorFallback;