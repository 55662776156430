import React from 'react';
import { Volume2, VolumeX, ChevronUp, ChevronDown } from 'lucide-react';
import { useAudioStore } from '../../store/audioStore';

const AudioControls = () => {
  const { isMuted, toggleMute, speechRate, setSpeechRate } = useAudioStore();

  const handleSpeedChange = (increase: boolean) => {
    const newRate = increase 
      ? Math.min(2, speechRate + 0.1)
      : Math.max(0.5, speechRate - 0.1);
    setSpeechRate(Number(newRate.toFixed(1)));
  };

  return (
    <div className="flex items-center space-x-2">
      <button
        onClick={toggleMute}
        className="p-1 rounded-full hover:bg-gray-100 transition-colors"
        title={isMuted ? "Unmute" : "Mute"}
      >
        {isMuted ? (
          <VolumeX className="h-4 w-4 text-gray-500" />
        ) : (
          <Volume2 className="h-4 w-4 text-indigo-600" />
        )}
      </button>

      <div className="flex items-center space-x-1">
        <button
          onClick={() => handleSpeedChange(false)}
          className="p-1 rounded-full hover:bg-gray-100 transition-colors"
          title="Decrease speed"
        >
          <ChevronDown className="h-4 w-4 text-gray-500" />
        </button>
        <span className="text-xs font-medium text-gray-600 min-w-[32px] text-center">
          {speechRate}x
        </span>
        <button
          onClick={() => handleSpeedChange(true)}
          className="p-1 rounded-full hover:bg-gray-100 transition-colors"
          title="Increase speed"
        >
          <ChevronUp className="h-4 w-4 text-gray-500" />
        </button>
      </div>
    </div>
  );
};

export default AudioControls;