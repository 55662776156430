import React, { useState, useEffect } from 'react';
import { useAudioStore } from '../../store/audioStore';

const VoiceSettings = () => {
  const { isMuted, toggleMute, selectedVoice, setSelectedVoice, speechRate, setSpeechRate } = useAudioStore();
  const [availableVoices, setAvailableVoices] = useState<SpeechSynthesisVoice[]>([]);

  useEffect(() => {
    const loadVoices = () => {
      const voices = window.speechSynthesis.getVoices();
      setAvailableVoices(voices);
    };

    loadVoices();
    window.speechSynthesis.onvoiceschanged = loadVoices;

    return () => {
      window.speechSynthesis.onvoiceschanged = null;
    };
  }, []);

  return (
    <div className="space-y-4">
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Voice
        </label>
        <select
          className="w-full text-sm border rounded-md px-2 py-1.5"
          onChange={(e) => setSelectedVoice(e.target.value)}
          value={selectedVoice || ''}
        >
          <option value="">Default</option>
          {availableVoices.map(voice => (
            <option key={voice.name} value={voice.name}>
              {voice.name}
            </option>
          ))}
        </select>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Speed
        </label>
        <div className="flex items-center space-x-2">
          <input
            type="range"
            min="0.5"
            max="2"
            step="0.1"
            value={speechRate}
            onChange={(e) => setSpeechRate(parseFloat(e.target.value))}
            className="flex-1"
          />
          <span className="text-sm text-gray-600 w-12">{speechRate}x</span>
        </div>
      </div>

      <div className="flex items-center justify-between">
        <span className="text-sm font-medium text-gray-700">Mute</span>
        <button
          onClick={toggleMute}
          className="text-gray-700 hover:text-gray-900"
        >
          {isMuted ? (
            <span className="text-red-600">Muted</span>
          ) : (
            <span className="text-green-600">Unmuted</span>
          )}
        </button>
      </div>
    </div>
  );
};

export default VoiceSettings;