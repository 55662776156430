import React, { useState, useCallback, useEffect } from 'react';
import { useData } from '../../context/DataContext';
import { AlertCircle, Loader2, RefreshCw } from 'lucide-react';
import CountBadge from './CountBadge';
import Fuse from 'fuse.js';

const DuplicatesTab = () => {
  const { criteria, cptCodes, loadData } = useData();
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [duplicates, setDuplicates] = useState<any[]>([]);
  const [analyzing, setAnalyzing] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [progress, setProgress] = useState<number>(0);
  const [phase, setPhase] = useState<string>('');

  // Load initial data
  useEffect(() => {
    const initializeData = async () => {
      setLoading(true);
      setError(null);
      try {
        await Promise.all([
          loadData('criteria'),
          loadData('cptCodes')
        ]);
      } catch (err) {
        console.error('Error loading data:', err);
        setError('Failed to load data. Please refresh the page.');
      } finally {
        setLoading(false);
      }
    };
    initializeData();
  }, [loadData]);

  const findDuplicates = useCallback(async () => {
    if (!criteria.length && !cptCodes.length) {
      setError('No data available for analysis');
      return;
    }

    setAnalyzing(true);
    setError(null);
    setDuplicates([]);
    const duplicateGroups = [];

    try {
      // Analyze Guidelines
      if (criteria.length > 0) {
        setPhase('Analyzing Guidelines...');
        const guidelineFuse = new Fuse(criteria, {
          keys: ['guideline'],
          threshold: 0.85,
          includeScore: true,
          ignoreLocation: true,
          minMatchCharLength: 5
        });

        const processedGuidelines = new Set();
        
        for (let i = 0; i < criteria.length; i++) {
          setProgress((i / criteria.length) * 50);
          
          const item = criteria[i];
          if (!processedGuidelines.has(item.guideline)) {
            const results = guidelineFuse.search(item.guideline);
            const similarGuidelines = results
              .filter(result => 
                result.item.guideline !== item.guideline && 
                result.score && 
                result.score < 0.15
              )
              .map(result => ({
                content: result.item.guideline,
                similarity: 1 - (result.score || 0),
                originalData: result.item
              }));

            if (similarGuidelines.length > 0) {
              duplicateGroups.push({
                type: 'guideline',
                items: [
                  { 
                    content: item.guideline, 
                    similarity: 1,
                    originalData: item 
                  },
                  ...similarGuidelines
                ]
              });
            }
            processedGuidelines.add(item.guideline);
          }
          
          // Allow UI to update
          if (i % 10 === 0) {
            await new Promise(resolve => setTimeout(resolve, 0));
          }
        }
      }

      // Analyze CPT Codes
      if (cptCodes.length > 0) {
        setPhase('Analyzing CPT Codes...');
        const processedCptCodes = new Map();
        
        cptCodes.forEach((item, index) => {
          setProgress(50 + (index / cptCodes.length) * 50);
          
          const key = item.code.toUpperCase();
          if (!processedCptCodes.has(key)) {
            processedCptCodes.set(key, []);
          }
          processedCptCodes.get(key).push(item);
        });

        processedCptCodes.forEach((items, code) => {
          if (items.length > 1) {
            duplicateGroups.push({
              type: 'cptCode',
              items: items.map(item => ({
                content: `${item.code} (${item.lob})`,
                similarity: 1,
                originalData: item
              }))
            });
          }
        });
      }

      setDuplicates(duplicateGroups);
      setProgress(100);
      setPhase('Analysis Complete');
      
      if (duplicateGroups.length === 0) {
        setError('No duplicates found in current dataset');
      }
    } catch (err) {
      console.error('Analysis failed:', err);
      setError(err instanceof Error ? err.message : 'Analysis failed');
    } finally {
      setAnalyzing(false);
    }
  }, [criteria, cptCodes]);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <Loader2 className="h-8 w-8 animate-spin text-gray-500" />
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <CountBadge count={duplicates.length} label="Duplicate Groups Found" />
        <button
          onClick={findDuplicates}
          disabled={analyzing}
          className="flex items-center px-6 py-3 bg-gradient-to-r from-black to-gray-800 text-white rounded-xl hover:from-gray-900 hover:to-black disabled:opacity-50 transition-all duration-300 shadow-lg hover:shadow-xl"
        >
          {analyzing ? (
            <>
              <Loader2 className="h-4 w-4 mr-2 animate-spin" />
              Analyzing...
            </>
          ) : (
            <>
              <RefreshCw className="h-4 w-4 mr-2" />
              Analyze Duplicates
            </>
          )}
        </button>
      </div>

      {analyzing && (
        <div className="flowing-gradient-fast p-6 rounded-xl">
          <div className="flex items-center mb-3">
            <Loader2 className="h-5 w-5 text-blue-500 animate-spin mr-2" />
            <span className="text-sm font-medium text-blue-600">{phase}</span>
          </div>
          <div className="relative w-full h-2 bg-gray-100 rounded-full overflow-hidden">
            <div
              className="absolute top-0 left-0 h-full progress-gradient rounded-full transition-all duration-300"
              style={{ width: `${progress}%` }}
            />
          </div>
          <span className="block mt-2 text-xs text-blue-600">
            {Math.round(progress)}% complete
          </span>
        </div>
      )}

      {error && (
        <div className="flowing-gradient p-4 rounded-xl flex items-center">
          <AlertCircle className="h-5 w-5 text-red-400 mr-2" />
          <span className="text-sm text-red-700">{error}</span>
        </div>
      )}

      {duplicates.length > 0 && (
        <div className="space-y-4">
          {duplicates.map((group, index) => (
            <div key={index} className="flowing-gradient rounded-xl p-6">
              <div className="flex justify-between items-center mb-4">
                <h3 className="text-sm font-medium bg-gradient-to-r from-gray-900 to-gray-600 bg-clip-text text-transparent">
                  {group.type === 'guideline' ? 'Similar Guidelines' : 'Duplicate CPT Codes'}
                </h3>
                <span className="px-3 py-1 text-xs font-medium text-blue-600 bg-blue-50 rounded-full">
                  {group.items.length} items
                </span>
              </div>
              <div className="space-y-3">
                {group.items.map((item, itemIndex) => (
                  <div
                    key={itemIndex}
                    className="flex items-center justify-between p-4 rounded-lg bg-gradient-to-r from-gray-50 to-white border border-gray-100/50 shadow-sm hover:shadow-md transition-all duration-300"
                  >
                    <div>
                      <p className="text-sm text-gray-900">{item.content}</p>
                      <div className="flex items-center mt-1">
                        <div className="w-16 h-1 rounded-full bg-gray-100 overflow-hidden">
                          <div 
                            className="h-full progress-gradient rounded-full"
                            style={{ width: `${Math.round(item.similarity * 100)}%` }}
                          />
                        </div>
                        <span className="ml-2 text-xs text-gray-500">
                          {Math.round(item.similarity * 100)}% match
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default DuplicatesTab;