import React, { useState, useEffect } from 'react';
import { CreditCard } from 'lucide-react';
import { BillingStats } from '../../components/admin/billing/BillingStats';
import { PriceSettings } from '../../components/admin/billing/PriceSettings';
import { PaymentHistory } from '../../components/admin/billing/PaymentHistory';
import { SubscriptionAlertManager } from '../../components/admin/SubscriptionAlertManager';
import StripeSetupGuide from '../../components/admin/StripeSetupGuide';

const BillingManagement = () => {
  const [showSetupGuide, setShowSetupGuide] = useState(true);
  const [monthlyRevenue, setMonthlyRevenue] = useState(0);
  const [activeSubscriptions, setActiveSubscriptions] = useState(0);
  const [canceledSubscriptions, setCanceledSubscriptions] = useState(0);
  const [atRiskSubscriptions, setAtRiskSubscriptions] = useState(0);

  useEffect(() => {
    const fetchBillingStats = async () => {
      try {
        // In a real app, these would come from your API
        setMonthlyRevenue(9900);
        setActiveSubscriptions(100);
        setCanceledSubscriptions(5);
        setAtRiskSubscriptions(3);
      } catch (error) {
        console.error('Error fetching billing stats:', error);
      }
    };

    fetchBillingStats();
  }, []);

  return (
    <div className="max-w-7xl mx-auto px-4 py-6">
      <div className="flex items-center justify-between mb-6">
        <div className="flex items-center">
          <CreditCard className="h-6 w-6 text-indigo-600 mr-2" />
          <h1 className="text-2xl font-bold text-gray-900">Billing Management</h1>
        </div>
        <button
          onClick={() => setShowSetupGuide(!showSetupGuide)}
          className="text-sm text-indigo-600 hover:text-indigo-800"
        >
          {showSetupGuide ? 'Hide Setup Guide' : 'Show Setup Guide'}
        </button>
      </div>

      {showSetupGuide && <StripeSetupGuide />}

      <BillingStats
        monthlyRevenue={monthlyRevenue}
        activeSubscriptions={activeSubscriptions}
        canceledSubscriptions={canceledSubscriptions}
        atRiskSubscriptions={atRiskSubscriptions}
      />

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mt-6">
        <PriceSettings />
        <SubscriptionAlertManager />
      </div>

      <PaymentHistory />
    </div>
  );
};

export default BillingManagement;