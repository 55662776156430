import React, { createContext, useContext, useState, useCallback, useEffect } from 'react';
import type { CptCode, Criteria, Procedure } from '../types';
import { LocalStorageService } from '../services/LocalStorageService';
import { DataAnalyzer } from '../lib/diagnostics/DataAnalyzer';

interface DataContextType {
  cptCodes: CptCode[];
  criteria: Criteria[];
  procedures: Procedure[];
  loading: { [key: string]: boolean };
  error: string | null;
  loadData: (collectionName: string) => Promise<void>;
  updateData: (collectionName: string, data: any[]) => Promise<void>;
}

const DataContext = createContext<DataContextType>({
  cptCodes: [],
  criteria: [],
  procedures: [],
  loading: {},
  error: null,
  loadData: async () => {},
  updateData: async () => {}
});

export const useData = () => {
  const context = useContext(DataContext);
  if (!context) {
    throw new Error('useData must be used within a DataProvider');
  }
  return context;
};

export const DataProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [state, setState] = useState<{
    cptCodes: CptCode[];
    criteria: Criteria[];
    procedures: Procedure[];
    loading: { [key: string]: boolean };
    error: string | null;
  }>({
    cptCodes: [],
    criteria: [],
    procedures: [],
    loading: {},
    error: null
  });

  const loadData = useCallback(async (collectionName: string) => {
    const startTime = performance.now();
    setState(prev => ({
      ...prev,
      loading: { ...prev.loading, [collectionName]: true }
    }));

    try {
      const data = await LocalStorageService.getData(collectionName);
      setState(prev => ({
        ...prev,
        [collectionName]: data,
        loading: { ...prev.loading, [collectionName]: false },
        error: null
      }));

      DataAnalyzer.recordOperation({
        type: 'read',
        collection: collectionName,
        duration: performance.now() - startTime,
        success: true
      });
    } catch (error) {
      console.error(`Error loading ${collectionName}:`, error);
      setState(prev => ({
        ...prev,
        error: `Failed to load ${collectionName}`,
        loading: { ...prev.loading, [collectionName]: false }
      }));

      DataAnalyzer.recordOperation({
        type: 'read',
        collection: collectionName,
        duration: performance.now() - startTime,
        success: false,
        error: error instanceof Error ? error.message : 'Unknown error'
      });
    }
  }, []);

  const updateData = useCallback(async (collectionName: string, data: any[]) => {
    const startTime = performance.now();
    setState(prev => ({
      ...prev,
      loading: { ...prev.loading, [collectionName]: true }
    }));

    try {
      await LocalStorageService.setData(collectionName, data);
      setState(prev => ({
        ...prev,
        [collectionName]: data,
        loading: { ...prev.loading, [collectionName]: false },
        error: null
      }));

      DataAnalyzer.recordOperation({
        type: 'write',
        collection: collectionName,
        duration: performance.now() - startTime,
        success: true
      });
    } catch (error) {
      console.error(`Error updating ${collectionName}:`, error);
      setState(prev => ({
        ...prev,
        error: `Failed to update ${collectionName}`,
        loading: { ...prev.loading, [collectionName]: false }
      }));

      DataAnalyzer.recordOperation({
        type: 'write',
        collection: collectionName,
        duration: performance.now() - startTime,
        success: false,
        error: error instanceof Error ? error.message : 'Unknown error'
      });
    }
  }, []);

  // Load initial data when component mounts
  useEffect(() => {
    const loadInitialData = async () => {
      try {
        await Promise.all([
          loadData('cptCodes'),
          loadData('criteria'),
          loadData('procedures')
        ]);
      } catch (error) {
        console.error('Error loading initial data:', error);
      }
    };

    loadInitialData();
  }, [loadData]);

  return (
    <DataContext.Provider value={{
      ...state,
      loadData,
      updateData
    }}>
      {children}
    </DataContext.Provider>
  );
};