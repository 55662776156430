import React, { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormStore } from '../store/formStore';
import { useOCRProcessing } from '../hooks/useOCRProcessing';
import FileDropzone from '../components/ocr/FileDropzone';
import ExtractedTextPreview from '../components/ocr/ExtractedTextPreview';
import OCRProgress from '../components/ocr/OCRProgress';
import OCRError from '../components/ocr/OCRError';
import OCRHeader from '../components/ocr/OCRHeader';
import { Eraser } from 'lucide-react';

const DocumentOCR = () => {
  const navigate = useNavigate();
  const { setFormData, clearForm } = useFormStore();
  const { processing, error, processFiles } = useOCRProcessing();
  const [extractedText, setExtractedText] = useState('');
  const [progress, setProgress] = useState(0);

  const handleFiles = useCallback(async (files: File[]) => {
    try {
      setProgress(0);
      setExtractedText('');
      
      const totalPages = files.reduce((acc, file) => acc + (file.size / 100000), 0);
      let processedPages = 0;

      for (const file of files) {
        const text = await processFiles([file]);
        if (text) {
          setExtractedText(prev => prev ? `${prev}\n\n${text}` : text);
          processedPages += file.size / 100000;
          setProgress((processedPages / totalPages) * 100);
        }
      }
    } catch (err) {
      console.error('OCR processing error:', err);
    }
  }, [processFiles]);

  const handleImport = useCallback(() => {
    if (extractedText) {
      setFormData({ clinicalNotes: extractedText });
      navigate('/dashboard');
    }
  }, [extractedText, setFormData, navigate]);

  const handleClear = useCallback(() => {
    setExtractedText('');
    setProgress(0);
    clearForm();
  }, [clearForm]);

  return (
    <div className="min-h-screen bg-gradient-to-br from-indigo-900 via-blue-900 to-blue-800 py-8 px-4">
      <div className="max-w-4xl mx-auto">
        <div className="relative group mb-8">
          <div className="absolute inset-0 bg-gradient-to-r from-blue-500/30 to-indigo-500/30 rounded-xl blur-xl"></div>
          <div className="relative backdrop-blur-md bg-white/10 border border-white/20 p-6 rounded-xl">
            <div className="flex justify-between items-center mb-6">
              <OCRHeader />
              <button
                onClick={handleClear}
                className="flex items-center px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 transition-colors"
                disabled={processing || !extractedText}
              >
                <Eraser className="h-4 w-4 mr-2" />
                Clear
              </button>
            </div>
            
            <p className="text-white/80 mb-6">
              Upload documents to extract text using OCR technology. 
              Supports up to 40 pages total. Formats: PDF, PNG, JPG, JPEG, GIF, BMP, WEBP.
            </p>

            <div className="space-y-6">
              <FileDropzone 
                onFilesAccepted={handleFiles}
                processing={processing}
              />

              <OCRError error={error} />
              <OCRProgress processing={processing} progress={progress} />

              {extractedText && (
                <ExtractedTextPreview 
                  text={extractedText}
                  onImport={handleImport}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DocumentOCR;