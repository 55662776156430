import React, { useState } from 'react';
import { Clock, Copy, Check, RefreshCw } from 'lucide-react';
import { Review } from '../types';
import { useData } from '../context/DataContext';
import { useAudioStore } from '../store/audioStore';
import AutoReadSummary from './AutoReadSummary';
import AudioControls from './audio/AudioControls';

interface ReviewOutputProps {
  review: Review;
  cptCodes?: string[];
  onRegenerate?: () => void;
}

const ReviewOutput: React.FC<ReviewOutputProps> = ({ review, cptCodes = [], onRegenerate }) => {
  const [copiedSection, setCopiedSection] = useState<string | null>(null);
  const { procedures } = useData();
  const { isMuted } = useAudioStore();

  const copyToClipboard = (text: string, section: string) => {
    navigator.clipboard.writeText(text);
    setCopiedSection(section);
    setTimeout(() => setCopiedSection(null), 2000);
  };

  // Get descriptions without CPT codes
  const descriptions = cptCodes
    ?.map(code => {
      const procedure = procedures.find(p => p.code === code);
      return procedure?.description;
    })
    .filter(Boolean);

  const sections = [
    { 
      title: 'Reason for Request', 
      content: descriptions?.length 
        ? descriptions.join('. ') + '.'
        : 'No CPT code descriptions available.'
    },
    { 
      title: 'Date of Service', 
      content: review.dateOfService ? new Date(review.dateOfService).toLocaleDateString('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric'
      }) : ''
    },
    { title: 'Medical History', content: review.medicalHistory },
    { title: 'Criteria Applied', content: review.criteriaApplied.join('\n') },
    { 
      title: 'Recommendation', 
      content: review.criteriaApplied.length > 0 
        ? review.recommendation 
        : 'Please select guidelines for review',
      showRegenerate: true
    },
    { title: 'Signature', content: review.signature },
    { title: 'Additional Note', content: review.additionalNote }
  ];

  return (
    <div className="bg-white rounded-lg shadow p-6">
      <div className="mb-4 flex justify-between items-center">
        <h2 className="text-lg font-medium text-gray-900">Generated Review</h2>
        <div className="flex items-center space-x-4">
          <AudioControls />
          <button
            onClick={() => copyToClipboard(
              sections.map(s => `${s.title}: ${s.content}`).join('\n\n'),
              'all'
            )}
            className="text-indigo-600 hover:text-indigo-700 flex items-center space-x-1"
          >
            {copiedSection === 'all' ? (
              <>
                <Check className="h-4 w-4" />
                <span>Copied!</span>
              </>
            ) : (
              <>
                <Copy className="h-4 w-4" />
                <span>Copy All</span>
              </>
            )}
          </button>
        </div>
      </div>

      <div className="space-y-4">
        {sections.map((section) => (
          <div key={section.title} className="flowing-gradient rounded-lg p-4">
            <div className="flex justify-between items-center mb-2">
              <div className="flex items-center space-x-2">
                <h3 className="text-sm font-medium text-gray-700">{section.title}</h3>
                <button
                  onClick={() => copyToClipboard(section.content, section.title)}
                  className="text-indigo-600 hover:text-indigo-700"
                  title="Copy to clipboard"
                >
                  {copiedSection === section.title ? (
                    <Check className="h-4 w-4" />
                  ) : (
                    <Copy className="h-4 w-4" />
                  )}
                </button>
                {section.showRegenerate && onRegenerate && (
                  <button
                    onClick={onRegenerate}
                    className="text-indigo-600 hover:text-indigo-700 flex items-center space-x-1 ml-2"
                  >
                    <RefreshCw className="h-4 w-4" />
                    <span>Regenerate</span>
                  </button>
                )}
              </div>
              {!isMuted && <AutoReadSummary text={section.content} />}
            </div>
            <div className="text-sm text-gray-800 whitespace-pre-wrap">
              {section.content}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ReviewOutput;