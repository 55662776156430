import React, { useState, useEffect } from 'react';
import { MessageSquare } from 'lucide-react';
import { OpenAIService } from '../services/openai/service';
import ChatHeader from './chat/ChatHeader';
import ChatMessages from './chat/ChatMessages';
import ChatInput from './chat/ChatInput';
import { Message } from '../types';
import { useData } from '../context/DataContext';

interface AIChatProps {
  clinicalNotes: string;
  guidelines: string[];
  analysis: {
    summary: string;
    review: string;
  };
  onDecisionUpdate?: (newDecision: string) => void;
}

const AIChat: React.FC<AIChatProps> = ({ 
  clinicalNotes, 
  guidelines, 
  analysis,
  onDecisionUpdate 
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [messages, setMessages] = useState<Message[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { criteria } = useData();

  // Add initial welcome message
  useEffect(() => {
    if (messages.length === 0) {
      setMessages([{
        role: 'ai',
        content: `Hello! I can help analyze the clinical notes and guidelines. I have access to all ${criteria.length} guidelines in the system. You can:

1. Ask about specific parts of the clinical notes
2. Question the recommendation decision
3. Request clarification on guideline criteria
4. Suggest decision changes if you disagree

How can I assist you?`
      }]);
    }
  }, [criteria.length]);

  const handleSubmit = async (message: string) => {
    if (!message.trim() || isLoading) return;

    const userMessage: Message = { role: 'user', content: message };
    setMessages(prev => [...prev, userMessage]);
    setIsLoading(true);

    try {
      // Check if user is suggesting a decision change
      const meetMatch = message.match(/should (meet|not meet)/i);
      if (meetMatch) {
        const suggestion = meetMatch[1].toLowerCase().includes('not') ? 'not meet' : 'meet';
        
        // Review the decision
        const newDecision = await OpenAIService.reviewDecision(
          clinicalNotes,
          guidelines.join('\n'),
          analysis.review,
          suggestion as 'meet' | 'not meet'
        );

        // Update the decision if callback provided
        if (onDecisionUpdate) {
          onDecisionUpdate(newDecision);
        }

        const aiMessage: Message = {
          role: 'ai',
          content: `I've reviewed the decision based on your suggestion. The updated analysis has been applied. Please review the changes in the recommendation section.`
        };
        setMessages(prev => [...prev, aiMessage]);
      } else {
        // Handle general questions about the notes/guidelines
        const response = await OpenAIService.analyzeClinicalNotes(
          `${clinicalNotes}\n\nCurrent Review:\n${analysis.review}\n\nUser Question:\n${message}`,
          'recommendation',
          guidelines
        );

        const aiMessage: Message = {
          role: 'ai',
          content: response.review || 'I apologize, but I could not generate a response.'
        };
        setMessages(prev => [...prev, aiMessage]);
      }
    } catch (error) {
      console.error('Error getting AI response:', error);
      const errorMessage: Message = {
        role: 'ai',
        content: 'I apologize, but I encountered an error. Please try again.'
      };
      setMessages(prev => [...prev, errorMessage]);
    } finally {
      setIsLoading(false);
    }
  };

  if (!isOpen) {
    return (
      <button
        onClick={() => setIsOpen(true)}
        className="fixed bottom-4 right-4 bg-black text-white rounded-full p-4 shadow-lg hover:bg-gray-900 transition-colors"
        title="Chat with AI"
      >
        <MessageSquare className="h-6 w-6" />
      </button>
    );
  }

  return (
    <div className="fixed bottom-4 right-4 w-96 bg-white rounded-2xl shadow-xl border border-gray-200">
      <ChatHeader onClose={() => setIsOpen(false)} />
      <ChatMessages messages={messages} loading={isLoading} />
      <ChatInput onSubmit={handleSubmit} disabled={isLoading} />
    </div>
  );
};

export default AIChat;