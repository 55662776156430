import { NameRemovalService } from './nameRemoval';

export class TextProcessor {
  static processText(text: string): string {
    if (!text?.trim()) return '';

    let processed = text.trim();

    // Remove sensitive information
    processed = NameRemovalService.removeNames(processed);

    // Clean up whitespace
    processed = processed
      .replace(/\s+/g, ' ')
      .replace(/\n\s*\n/g, '\n\n')
      .trim();

    return processed;
  }
}