const SESSION_KEY = 'active_session';
const SESSION_ID_KEY = 'session_id';
const SESSION_TIMEOUT = Number(import.meta.env.VITE_SESSION_TIMEOUT) || 1800000; // 30 minutes

export const SessionManager = {
  createSession: (isAdmin: boolean = false) => {
    try {
      const sessionId = crypto.randomUUID();
      const session = {
        id: sessionId,
        timestamp: Date.now(),
        deviceId: navigator.userAgent,
        lastActivity: Date.now(),
        isAdmin
      };
      localStorage.setItem(SESSION_KEY, JSON.stringify(session));
      localStorage.setItem(SESSION_ID_KEY, sessionId);
    } catch (error) {
      console.warn('Error creating session:', error);
    }
  },

  clearSession: () => {
    try {
      localStorage.removeItem(SESSION_KEY);
      localStorage.removeItem(SESSION_ID_KEY);
    } catch (error) {
      console.warn('Error clearing session:', error);
    }
  },

  hasActiveSession: () => {
    try {
      const session = localStorage.getItem(SESSION_KEY);
      if (!session) return false;

      const sessionData = JSON.parse(session);
      const currentSessionId = localStorage.getItem(SESSION_ID_KEY);
      
      // Check if this is the same session
      if (sessionData.id !== currentSessionId) {
        SessionManager.clearSession();
        return false;
      }

      // Only check device ID for non-admin users
      if (!sessionData.isAdmin && sessionData.deviceId !== navigator.userAgent) {
        SessionManager.clearSession();
        return false;
      }

      // Check session timeout
      if (Date.now() - sessionData.lastActivity > SESSION_TIMEOUT) {
        SessionManager.clearSession();
        return false;
      }

      // Update last activity
      sessionData.lastActivity = Date.now();
      localStorage.setItem(SESSION_KEY, JSON.stringify(sessionData));
      return true;
    } catch (error) {
      console.warn('Error checking session:', error);
      return false;
    }
  },

  validateSession: (isAdmin: boolean): boolean => {
    try {
      const hasSession = SessionManager.hasActiveSession();
      if (!hasSession) {
        SessionManager.clearSession();
        return false;
      }
      return true;
    } catch (error) {
      console.warn('Error validating session:', error);
      return false;
    }
  },

  refreshSession: () => {
    try {
      const session = localStorage.getItem(SESSION_KEY);
      if (session) {
        const sessionData = JSON.parse(session);
        sessionData.lastActivity = Date.now();
        localStorage.setItem(SESSION_KEY, JSON.stringify(sessionData));
      }
    } catch (error) {
      console.warn('Error refreshing session:', error);
    }
  }
};