import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FileText, FileUp } from 'lucide-react';
import NavActions from './navbar/NavActions';

const Navbar = () => {
  const location = useLocation();
  const publicRoutes = ['/landing', '/login', '/subscribe'];
  const showNavbar = !publicRoutes.includes(location.pathname);

  if (!showNavbar) {
    return null;
  }

  return (
    <nav className="bg-white border-b border-claude-border">
      <div className="max-w-7xl mx-auto px-4">
        <div className="flex justify-between h-16">
          <div className="flex items-center space-x-8">
            <Link to="/" className="flex items-center">
              <FileText className="h-6 w-6 mr-2" />
              <span className="font-medium text-xl text-gray-900">UM Note Taker</span>
            </Link>
            
            <Link 
              to="/ocr"
              className="flex items-center px-3 py-2 rounded-lg text-sm font-medium text-gray-700 hover:bg-gray-100"
            >
              <FileUp className="h-5 w-5 mr-2" />
              Document OCR
            </Link>
          </div>
          
          <div className="flex items-center">
            <NavActions />
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;