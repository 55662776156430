import React from 'react';
import { Bell, Mail, AlertCircle, Check } from 'lucide-react';

interface Alert {
  id: string;
  type: 'payment_failed' | 'subscription_ending' | 'subscription_canceled';
  subscriber: string;
  email: string;
  date: string;
  status: 'pending' | 'sent' | 'failed';
}

interface SubscriptionAlertListProps {
  alerts: Alert[];
  onSendReminder: (alert: Alert) => Promise<void>;
}

export const SubscriptionAlertList: React.FC<SubscriptionAlertListProps> = ({
  alerts,
  onSendReminder
}) => {
  return (
    <div className="bg-white rounded-lg shadow-sm p-6">
      <div className="flex items-center justify-between mb-4">
        <h3 className="text-lg font-medium">Subscription Alerts</h3>
        <Bell className="h-5 w-5 text-gray-400" />
      </div>

      <div className="space-y-4">
        {alerts.map((alert) => (
          <div
            key={alert.id}
            className="flex items-center justify-between p-4 bg-gray-50 rounded-lg"
          >
            <div>
              <p className="font-medium text-gray-900">{alert.subscriber}</p>
              <p className="text-sm text-gray-500">
                {alert.type === 'payment_failed' && 'Payment failed'}
                {alert.type === 'subscription_ending' && 'Subscription ending soon'}
                {alert.type === 'subscription_canceled' && 'Subscription canceled'}
              </p>
              <p className="text-xs text-gray-400">{new Date(alert.date).toLocaleDateString()}</p>
            </div>
            <div className="flex items-center space-x-4">
              {alert.status === 'pending' && (
                <button
                  onClick={() => onSendReminder(alert)}
                  className="flex items-center px-3 py-1 text-sm text-indigo-600 hover:text-indigo-800"
                >
                  <Mail className="h-4 w-4 mr-1" />
                  Send Reminder
                </button>
              )}
              {alert.status === 'sent' && (
                <span className="flex items-center text-green-600">
                  <Check className="h-4 w-4 mr-1" />
                  Sent
                </span>
              )}
              {alert.status === 'failed' && (
                <span className="flex items-center text-red-600">
                  <AlertCircle className="h-4 w-4 mr-1" />
                  Failed
                </span>
              )}
            </div>
          </div>
        ))}

        {alerts.length === 0 && (
          <p className="text-center text-gray-500 py-4">No alerts at this time</p>
        )}
      </div>
    </div>
  );
};