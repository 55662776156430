import React from 'react';
import { SpeechButton } from './speech/SpeechButton';
import { useSpeechSynthesis } from './speech/useSpeechSynthesis';

interface AutoReadSummaryProps {
  text: string;
  autoPlay?: boolean;
}

const AutoReadSummary: React.FC<AutoReadSummaryProps> = ({ text, autoPlay = false }) => {
  const { isPlaying, isMuted, startSpeaking, stopSpeaking } = useSpeechSynthesis(text, autoPlay);

  return (
    <div className="flex items-center space-x-2">
      <SpeechButton
        isPlaying={isPlaying}
        isMuted={isMuted}
        onStart={startSpeaking}
        onStop={stopSpeaking}
      />
    </div>
  );
};

export default AutoReadSummary;