import Papa from 'papaparse';
import * as XLSX from 'xlsx';

interface DiagnosticReport {
  fileType: string;
  headerAnalysis: {
    detectedHeaders: string[];
    normalizedHeaders: string[];
    mappedFields: Record<string, string>;
  };
  dataAnalysis: {
    totalRows: number;
    emptyRows: number;
    validRows: number;
    sampleData: any[];
    missingRequiredFields: string[];
  };
  errors: string[];
}

export class DataDiagnostics {
  static async analyzeFile(file: File): Promise<DiagnosticReport> {
    const report: DiagnosticReport = {
      fileType: file.type,
      headerAnalysis: {
        detectedHeaders: [],
        normalizedHeaders: [],
        mappedFields: {}
      },
      dataAnalysis: {
        totalRows: 0,
        emptyRows: 0,
        validRows: 0,
        sampleData: [],
        missingRequiredFields: []
      },
      errors: []
    };

    try {
      if (file.name.toLowerCase().endsWith('.csv')) {
        await this.analyzeCSV(file, report);
      } else if (file.name.toLowerCase().match(/\.xlsx?$/)) {
        await this.analyzeExcel(file, report);
      }
    } catch (error) {
      report.errors.push(`Analysis error: ${error instanceof Error ? error.message : 'Unknown error'}`);
    }

    return report;
  }

  private static async analyzeCSV(file: File, report: DiagnosticReport): Promise<void> {
    return new Promise((resolve, reject) => {
      Papa.parse(file, {
        header: true,
        skipEmptyLines: 'greedy',
        preview: 5,
        complete: (results) => {
          this.processParseResults(results, report);
          resolve();
        },
        error: (error) => {
          report.errors.push(`CSV parsing error: ${error.message}`);
          reject(error);
        }
      });
    });
  }

  private static async analyzeExcel(file: File, report: DiagnosticReport): Promise<void> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          const data = e.target?.result;
          if (!data) {
            throw new Error('Failed to read Excel file');
          }

          const workbook = XLSX.read(data, { type: 'binary' });
          const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
          
          // Analyze headers
          const range = XLSX.utils.decode_range(firstSheet['!ref'] || 'A1:Z1');
          const headers: string[] = [];
          
          for (let C = range.s.c; C <= range.e.c; ++C) {
            const cell = firstSheet[XLSX.utils.encode_cell({ r: range.s.r, c: C })];
            headers.push(cell ? cell.v.toString() : '');
          }

          report.headerAnalysis.detectedHeaders = headers;
          report.headerAnalysis.normalizedHeaders = headers.map(h => this.normalizeHeader(h));
          
          // Get sample data
          const jsonData = XLSX.utils.sheet_to_json(firstSheet, { header: 1 });
          report.dataAnalysis.totalRows = jsonData.length - 1; // Exclude header row
          report.dataAnalysis.sampleData = jsonData.slice(1, 6);

          resolve();
        } catch (error) {
          report.errors.push(`Excel parsing error: ${error instanceof Error ? error.message : 'Unknown error'}`);
          reject(error);
        }
      };
      reader.onerror = () => {
        report.errors.push('File reading error');
        reject(new Error('File reading error'));
      };
      reader.readAsBinaryString(file);
    });
  }

  private static processParseResults(results: Papa.ParseResult<any>, report: DiagnosticReport): void {
    report.headerAnalysis.detectedHeaders = results.meta.fields || [];
    report.headerAnalysis.normalizedHeaders = results.meta.fields?.map(f => this.normalizeHeader(f)) || [];
    
    report.dataAnalysis.totalRows = results.data.length;
    report.dataAnalysis.sampleData = results.data.slice(0, 5);
    
    // Analyze field mappings
    report.headerAnalysis.mappedFields = {};
    results.meta.fields?.forEach(field => {
      report.headerAnalysis.mappedFields[field] = this.normalizeHeader(field);
    });

    // Check for required fields
    const requiredFields = ['code', 'cptcode', 'guideline', 'criteria'];
    report.dataAnalysis.missingRequiredFields = requiredFields.filter(field => 
      !report.headerAnalysis.normalizedHeaders.includes(field)
    );
  }

  private static normalizeHeader(header: string): string {
    const normalized = header.toLowerCase().trim();
    
    // CPT Code mappings
    if (['cptcode', 'cpt code', 'cpt', 'code', 'cpt_code'].includes(normalized)) {
      return 'code';
    }
    
    // Criteria mappings
    if (['criteria', 'criterion', 'description'].includes(normalized)) {
      return 'criteria';
    }

    return normalized;
  }
}